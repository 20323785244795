.BoxColumn {
  display: flex;
  flex-direction: column;
}

.BoxRow {
  display: flex;
  flex-direction: row;
}

/* Plan details */

.changePlanBox {
  border: 1px solid #ffffff4d;
  border-radius: 16px;
  padding: 0px 0px 10px 0px;
  width: 280px;
  min-width: 230px;
  position: relative;
  border: var(--cart-cart-quantity, 1px) solid #e6e6e6;
}

.changePlanMonthsBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px;
  width: 100%;
  border: 1px solid #2bc7c9;
  border-radius: 53px;
  background-image: linear-gradient(
    270deg,
    rgba(43, 199, 201, 0.08) -1.53%,
    rgba(27, 196, 125, 0.08) 68.84%,
    rgba(24, 160, 251, 0.08) 101.83%
  );
}

.changePlanText {
  font-family: Euclid Circular B !important;
  font-size: 14px;
  font-weight: 400;
}

.changePlanBoxIn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: var(--cart-cart-quantity, 1px) solid #e6e6e6;
  background: rgba(13, 43, 44, 0.04);
  padding: 6px 0px;
  border-radius: 16px 16px 0px 0px;
}

.planDetailsBox {
  border: 1px solid #0000001a;
  border-radius: 12px;
  width: 300px;
  background-color: #eff1f0;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.bookingDetailsHeading {
  font-family: Euclid Circular B !important;
  font-size: 10px !important;
  font-weight: 500;
}

.bookingDetailsValue {
  font-weight: 400;
  font-size: 12px !important;
  font-family: Euclid Circular B;
  overflow: hidden;
  width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.paymentDetailsPopUp {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  background: linear-gradient(
    270.37deg,
    rgba(43, 199, 201, 0.08) -1.53%,
    rgba(27, 196, 125, 0.08) 68.84%,
    rgba(24, 160, 251, 0.08) 101.83%
  );
  padding: 4px 10px;
}

.headingPlanDetails {
  display: flex;
  align-items: center;
  padding: 6px 0px 1px 14px;
  background: #e4e4e4;
  border-radius: 11px 11px 0px 0px;
  justify-content: space-between;
}

.btnPopup {
  position: fixed;
  bottom: 0;
  background: #fff;
  padding: 5px;
}

.AddOnesBox {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.warningWrapper {
  padding: 15px;
  border-radius: 12px;
  border: 1px solid #ffc60066;
  background: #ffbf603d;
  margin-bottom: 10px;
}

.warningWrapperContent {
  display: flex;
  flex-direction: row;
  gap: 32px;
  flex: 1 0 0;
}

.PlanDetailsCards {
  gap: 15px;
  padding-top: 20px;
  display: flex;
  flex-direction: row;
}

.flexedItems {
  flex: 1 0 calc(25% - 20px);
}

.documentUploadBox {
  border: dotted;
  width: 150px;
  height: 133px;
  border-radius: 5px;
  color: #d0d5dd;
  display: flex;
  padding: 10px;
  cursor: pointer;
}

.docImgPreviewBox {
  height: 50px;
  width: 50px;
  border: 2px solid #d0d5dd;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.dataTableStatusHeader {
  display: flex;
  align-items: center;
}
/* Image preview modal */

.imagePreviewModal {
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
  color: white;
  border-radius: 0px 0px 2rem 2rem;
  padding: 5px;
}

.downloadButton {
  position: relative;
  bottom: 30px;
  left: 44%;
}
/* ------------------------------------- */

/* -------------------------------- PLAN DETAIL DRAWER ---------------------------------- */
.drawerHighlightBox {
  border: 1px solid lightgray;
  border-radius: 12px;
  padding: 1px 0px;
  background-color: #fff;
  padding: 10px;
}

/* ------------------------------------- */
/* ---------------------VEHICLE DETAILS---------------------- */
.iconWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 43px;
  height: 43px;
  background-color: #e9e9e9;
  border-radius: 64px;
}

/* end ride modal  */

.EndRide_StartReading {
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 0px 12px 0px 0px;
  border-left: 3px solid #276162;
  height: 50px;
  box-shadow: 2px 2px 40px 0px #00000014;
  padding: 16px 24px 16px 24px;
  justify-content: space-between;
}

.fileUploadIN {
  background-color: #00000014;
  border-radius: 17.48px;
  padding: 24px;
  display: flex;
  align-items: flex-start;
  gap: 22px;
  justify-content: space-between;
}

.ImageUploadUi {
  width: 65px;
  height: 65px;
  border-radius: 16px;
  display: flex;
  padding: 10px;
  border: 2px dotted #b1b1b4;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.buttonSideDrawer {
  position: fixed;
  bottom: 0;
  display: flex;
  z-index: 9999999;
  padding: 10px;
  background-color: #f6fbf9;
  margin-top: 35px;
}

.endRideCheckChallan {
  display: flex;
  background-color: #ffffff;
  padding: 16px;
  border-radius: 12px;
  justify-content: space-between;
}

.bookingDetailsBox {
  border-radius: 0px 8px 0px 0px;
  padding: 12px;
  background: #1bc47d14;
  display: flex;
  flex-wrap: wrap;
  gap: 21px 26px;
}

.bookingDetails {
  display: flex;
  flex-wrap: wrap;
  gap: 21px 40px;
  margin-top: 20px;
}

/* Plan details */

.changePlanBox {
  border: 1px solid #ffffff4d;
  border-radius: 16px;
  padding: 0px 0px 10px 0px;
  width: 280px;
  min-width: 230px;
  position: relative;
  border: var(--cart-cart-quantity, 1px) solid #e6e6e6;
}

.docAcceptNote {
  font-size: 9px;
  color: #d0d5dd;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.imgCrossIcon {
  position: absolute;
  right: -1px;
  top: -3px;
  width: 17px;
  height: 17px;
  cursor: pointer;
}

/* ------------------------------------- */

/* ------------------------------------- */
.modalImage {
  width: 4vw;
  border-radius: 50%;
}

.imgCrossIcon {
  position: absolute;
  right: -1px;
  top: -3px;
  width: 17px;
  height: 17px;
  cursor: pointer;
}

.dataTableStatusHeader {
  display: flex;
  align-items: center;
}
/* Image preview modal */

.imagePreviewModal {
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
  color: white;
  border-radius: 0px 0px 2rem 2rem;
  padding: 5px;
}

.downloadButton {
  position: relative;
  bottom: 30px;
  left: 44%;
}
/* ------------------------------------- */

/* -------------------------------- PLAN DETAIL DRAWER ---------------------------------- */
.drawerHighlightBox {
  border: 1px solid lightgray;
  border-radius: 12px;
  padding: 1px 0px;
  background-color: #fff;
  padding: 10px;
}

/* ------------------------------------- */
/* ---------------------VEHICLE DETAILS---------------------- */
.iconWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 43px;
  height: 43px;
  background-color: #e9e9e9;
  border-radius: 64px;
}
.modalImage {
  width: 4vw;
  border-radius: 50%;
}

.paymentBreakupExtendRide {
  position: fixed;
  bottom: 0;
  display: flex;
  z-index: 9999999;
  padding: 10px;

  width: 100%;
  margin-top: 35px;
}

.collapseUp {
  transition: max-height 0.3s ease-out;
  overflow: hidden;
  max-height: 0;
}
.collapseExpand {
  transition: max-height 0.3s ease-out;
  overflow: hidden;
  max-height: 200px; /* Adjust based on content size */
}

.yesNoIcons {
  width: 30px;
  height: 30px;
  border-radius: 100px;
  border: 1.5px solid;
  border-image-source: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(90deg, #01353d 0%, #2bc7c9 60%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.yesNoIcons:hover {
  background: linear-gradient(
    270.37deg,
    rgba(43, 199, 201, 0.16) -1.53%,
    rgba(27, 196, 125, 0.16) 68.84%,
    rgba(24, 160, 251, 0.16) 101.83%
  );
}

.yesNoIconsSelected {
  width: 30px;
  height: 30px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #01353d;
}

/* Responsiveness  */
@media screen and (max-width: 768px) {
  .PlanDetailsCards {
    flex-direction: column;
  }

  .warningWrapperContent {
    flex-direction: column;
  }
}

.customHtmlTableTdTh {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.backIcon_CompleteRide {
  background-color: #f2f2f2;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.completeRidePaymentDetails {
  background-color: #01353d14;
  border-radius: 0px 0px 12px 12px;
}
.loader {
  animation: spin 2s linear infinite;
}
