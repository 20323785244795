.BoxRow {
  display: flex;
  flex-direction: row;
}

.BoxColumn {
  display: flex;
  flex-direction: column;
}

/* Plans card */
.card {
  position: relative;
  cursor: pointer;
  border-radius: 8px !important;
}

.card.selected {
  background-image: linear-gradient(
    109.51deg,
    #053c3d 27.34%,
    #0d3d3e 60.5%,
    #124546 93.8%
  );
  border: 1px solid #09cacc;
  color: white;
}

.card:not(.selected) {
  background-image: linear-gradient(
    111.31deg,
    #f0f6f7 0%,
    #f1f1f1 32.1%,
    #f7f8f8 49.56%,
    #fcfcfc 93.74%
  );
}

.planCardBox {
  display: flex;
  justify-content: center;
  gap: 24px;
  padding: 25px 10px;
}

/* helmet badge */

.badge {
  /* border: 1px solid #636369; */
  border: 1px solid #000;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  right: -5px;
  /* z-index: 1; */
  background-color: white;
  padding: 0px 7px !important;
}

.helmetSelectButton {
  min-height: 24px;
  max-height: 35px;
  width: 7vw !important;
  font-size: 12px !important;
  text-transform: none !important;
  color: #000 !important;
  border: 1px solid #000;
  border-radius: 30px !important;
}

/* 
.helmetSelectButton::hover {
  border: 1px solid #1bd8da;
}
.badge::hover {
  border: 1px solid #1bd8da;
} */

.IncreasedSelectButton {
  display: flex;
  align-items: center;
  text-align: center;
  padding-left: 17px;
  padding-right: 17px;
  background: #1bd8da;
}

/* Select Box */

.select {
  border-radius: 8px !important;
  min-height: 42px;
  height: 5vh;
  margin: 0px;
  box-shadow: 1px 1px 2px 0px #00000021 inset, 11px 10px 32px 0px #0000000a,
    -19px -7px 32px 0px #3333330a;
}

.containerPadding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.customMultiSelect{
  height: 40px;
}
