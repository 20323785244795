.CardListContainer {
  display: flex;
  gap: 10px;
  flex-direction: row;
  overflow-x: auto;
  white-space: nowrap;
  width: 69vw;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  cursor: pointer;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media (max-width: 1136px) {
  .CardListContainer {
    width: 60vw;
  }
}

.CardListContainer > * {
  flex: 0 0 auto;
  margin-right: 10px;
  scroll-snap-align: start;
  /* Optional for snap scrolling */
}

.overlayText {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  text-align: center;
  color: #fff;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black; /* Transparent green */
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  border-radius: 10px;
  transition: opacity 0.3s;
}
.imageContainer:hover .overlay {
  opacity: 1;
  cursor: pointer;
}

.imageContainer {
  position: relative;
  width: 90px;
  height: 90px;
  border-radius: 10px;
}

.vehicleImage {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  transition: opacity 0.3s;
}

.imagePreviewModal {
    display: flex;
    align-items: center;
    justify-content: center;
    background: black;
    color: white;
    border-radius: 0px 0px 2rem 2rem;
    padding: 5px;
  }