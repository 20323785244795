.ReportCard {
  position: relative;
  cursor: pointer;
  border-radius: 12px !important;
  min-width: 300px;
  max-width: 350px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 1px 1px 2px 0px #00000021 inset, 11px 10px 32px 0px #0000000a,
    -19px -7px 32px 0px #3333330a;
  padding: 28px 25px;
}
