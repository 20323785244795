:root {
    --ad-primary: #115050;
    --ad-seconadary: #bfeeef;
    --ad-light: #2bc7c9;
    --ad-body-bg: #bfeeef;
    --ad-body-bg-light: #FBFBFB;
    --ad-body-bg-gray: #f8f8fa;
    --ad-primary-text: #092828;
    --ad-secondary-text: #797979;
    --ad-primary-light-text: #fefefe;
    --success: #1aa83c;
    --info: #0ca0b7;
    --warning: #ffc107;
    --danger: #f7384b;
    --light: #f8f9fa;
    --dark: #343a40;
}

body {
    color: var(--ad-primary-text) !important;
    background-color: var(--ad-body-bg-light) !important;
    font-family: 'Euclid Circular B' !important;
    scroll-behavior: smooth;
}

a {
    color: #115050;
    text-decoration: none;
    background-color: transparent;
}

a:hover {
    color: #092828;
    text-decoration: underline;
}

/* a:not([href]):not([tabindex]) {
    color: var(--ad-primary);
    text-decoration: none;
} */
/* 
a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
    color: var(--ad-primary);
    text-decoration: none;
} */

a:not([href]):not([tabindex]):focus {
    outline: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--ad-primary-text) !important;
}

.btn {
    padding: 12px 16px;
    cursor: pointer;
    border-width: 1px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    -webkit-box-shadow: 0px 10px 20px -6px rgb(0 0 0 / 12%);
    -moz-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 10px 20px -6px rgb(0 0 0 / 12%);
}

.btn.btn-ad-primary {
    background: var(--ad-primary);
    border-color: var(--ad-primary);
    color: #fff;
}

.btn.btn-ad-primary:hover,
.btn.btn-primary:focus {
    background-color: var(--ad-light);
    color: #fff;
}

.btn.btn-ad-primary.btn-link {
    background: transparent;
    color: #fff;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.btn.btn-round {
    border-radius: 40px;
}

.btn.btn-ad-outline-primary {
    color: var(--ad-primary);
    border-color: var(--ad-primary);
}

.btn.btn-ad-outline-primary:hover {
    color: #fff !important;
    background: var(--ad-light);
}

.btn.btn-ad-secondary {
    color: #fff;
    border-color: var(--ad-seconadary);
    background: var(--ad-seconadary);
}

.btn.btn-ad-secondary:hover,
.btn.btn-ad-secondary:focus {
    border-color: var(--ad-light);
    background: var(--ad-light)
}

.btn.btn-ad-secondary.btn-link {
    color: var(--ad-seconadary);
    background: transparent;
}

.btn.btn-ad-outline-secondary {
    color: var(--ad-primary);
    border-color: var(--ad-seconadary);
}

.btn.btn-ad-outline-secondary:hover {
    background: var(--ad-light);
    color: var(--ad-primary);
}

.card .card-header {
    background-image: linear-gradient(to left, #2bc7c9, #60d1d3, #83dbdc, #a2e4e6, #bfeeef);
}

/* Page wise CSS starts */
/* ------------ extend-ride css ---------------------*/
.package-wrap,
.hemlate-row,
.total-payment-row {
    padding: 20px;
}

.form-check {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 15px;
}

.form-check-input:checked {
    background-color: var(--light-green);
    border-color: var(--light-green);
}

.checkbox-theme .form-check-label {
    color: #707070;
    font-size: 14px;
    font-weight: 500;
    margin-left: 10px;
}
.Helmete-wrap .form-check-label{
    margin-left: 8px;
}
.extend-ride-wrapper .form-check-label{
    margin-left: 10px;
}
.extend-ride-wrapper .form-select,
.extend-ride-wrapper .form-control {
    font-size: 14px;
    padding: 12px 17px;
    color: var(--ad-secondary-text);
    border-radius: 10px;
}

.extend-ride-wrapper .form-label {
    font-size: 14px;
    font-weight: 400;
    width: 100%;
}

.Helmete-wrap .extra-head {
    font-size: 16px;
    font-weight: 400;
    margin-right: 20px;
}

.extra-Helmete {
    margin: 10px 20px 20px 0;
}

.extra-Helmete h4 {
    color: #707070;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 0;
}

.extra-Helmete .checkbox-theme .form-check-label {
    color: #707070;
    font-size: 14px;
    font-weight: 500;
    margin-left: 10px;
}

.helmete-option-row {
    display: flex;
}

.helmete-option-row .Helmete-options {
    border: solid 1px var(--ad-light);
    max-width: 100%;
    padding: 10px;
    border-radius: 10px;
    margin: 0;
    position: relative;
}

.helmete-option-row .Helmete-options:hover {
    -webkit-box-shadow: 0px 10px 20px -6px rgb(0 0 0 / 12%);
    -moz-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 10px 20px -6px rgb(0 0 0 / 12%);
}

.helmete-option-row .Helmete-options img {
    margin-right: 20px;
    max-width: 28%;
}

.helmete-option-row .Helmete-options.active {
    position: relative;
}

.helmete-option-row .Helmete-options.active::after {
    position: absolute;
    content: "\f00c";
    right: -9px;
    top: -9px;
    background: var(--light-green);
    width: 24px;
    height: 24px;
    border-radius: 100%;
    color: #fff;
    text-align: center;
    font: normal normal normal 16px/1 FontAwesome;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    border: solid 2px #fff;
}

.helmete-option-row a {
    text-decoration: none;
}

.extra-Helmete h3 {
    font-size: 1rem;
    position: relative;
    padding-bottom: 0;
    color: var(--dark-green);
    margin-bottom: 3px;
}

.comp-hel-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px 0;
}

.coupon-row .form-control {
    margin-bottom: 0;
    border-radius: 5px 0 0 5px !important;
}

.extend-ride-wrapper p {
    margin-bottom: 10px;
    font-size: 14px;
    color: var(--ad-secondary-text);
}

.total-payment-row .form-check {
    font-size: 16px;
    margin-bottom: 14px;
}

.final-pay-row {
    margin: 20px 0;
    background: #f7f7f7;
    padding: 16px;
    border-radius: 5px;
}

.pay-step {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.pay-step h5 {
    font-size: 16px;
    margin: 0;
    display: flex;
    align-items: center;
}

.paybox {
    padding: 8px 21px;
    border-radius: 5px;
    border: solid 2px var(--light-green);
    color: var(--dark-bg);
}

@media (max-width: 360px) {
    .Helmete-wrap {
        flex-wrap: wrap;
    }

    .Helmete-wrap .extra-head {
        width: 100%;
        margin-bottom: 10px;
    }
}

/* Edit Booking Details */
.edit-booking .form-check{
    display: inline-block;
}

.edit-booking .checkbox-theme .form-check-label{
    margin-left: 0px;
}

.truncate_text_singleLine {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}