.radio-button {
  padding: 0 100px 0 0;
}

.custom-heading {
  background-color: aqua;
  width: 200px;
}

.search-button {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 20px;
}

@media (max-width: 767px) {
  .radio-button {
    padding: 0;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .radio-button {
    padding: 0 20px 0 0;
  }
}
