.timeLinebody {
 font-family: Arial, sans-serif;
 /* background-color: #f7f7f7; */
 margin: 0;
 padding: 0;
}

.timeline {
 position: relative;
 width: "100%";
}

.dot-row {
 display: flex;
 align-items: flex-start;
 margin-bottom: 20px;
 position: relative;
 width: 100%;
}

.dot-container {
 width: 8%;
 margin-top: 22px;
 border: "1px solid red";
 /* background-color: red; */
 z-index: 999;
}

.dot {
 width: 12px;
 height: 12px;
 background-color: #2bc7c9;
 border-radius: 100%;
 z-index: 999;
}
.imageContainerHeadingText {
 color: "#00353D";
 font-size: 14px;
 font-family: "EuclidCircularB-SemiBold";
 margin-top: 5px;
 margin-bottom: 4px;
}

.content-heading-row {
 display: flex;
 width: 100%;
 height: auto;
 margin-top: 20px;
 margin-bottom: 20px;
 padding-left: 20px;
 padding-right: 20px;
 border: 1px solid red;
 /* margin: 20px 20px; */
}
.headingtasktext {
 font-size: 14px;
 font-weight: bold;
 font-family: "EuclidCircularB-Bold";
 color: #209597;
 white-space: nowrap;
}
.date-container-heading {
 width: 15%;
 padding-left: 30px;
 border: 1px solid green;
}
.description-created-heading {
 width: 70%;
 border: 1px solid green;
}
.chip-created-heading {
 width: 15%;
 border: 1px solid green;
}

.content-row {
 height: auto;
}

.time {
 height: auto;
}
.description {
 height: auto;
 background-color: #f6fffe;
 padding: 20px;
 border-radius: 5px;
}

.chip {
 height: auto;
}

.timeh {
 height: auto;
}

.descriptionh {
 height: auto;
 padding-left: 20px;
}

.chiph {
 height: auto;
}

.timeText {
 font-size: 14px;
 font-weight: bold;
 font-family: "EuclidCircularB-Medium";
 margin: 0;
}

.descText {
 font-size: 14px;
 font-weight: normal;
 word-wrap: break-word;
 color: #707070;
 font-family: "EuclidCircularB-Regular";
 margin: 0;
}
.descTitle {
 font-size: 14px;
 font-weight: normal;
 color: #006063;
 font-family: "EuclidCircularB-Medium";
 margin-bottom: 10;
}

.connector {
 position: absolute;
 top: 25px;
 left: 5px;
 width: 2px;
 height: 86%;
 background-color: #2bc7c980; /* Set the background color */
 background-image: repeating-linear-gradient(
  45deg,
  transparent,
  transparent 5px,
  #fff 5px,
  #fff 10px
 );
}
.chipButtonPrimary {
 display: inline-flex;
 justify-content: center;
 align-items: center;
 font-family: "EuclidCircularB-Regular";
 height: 30px;
 padding: 2px 10px;
 font-size: 14px;
 border-radius: 25px;
white-space: nowrap; ;
 background-color: rgba(1, 50, 87, 0.08);
 color: #013287;
 margin-bottom: 6px;
}
.chipButtonSecondary {
 display: flex;
 justify-content: center;
 align-items: center;
 font-family: "EuclidCircularB-Regular";
 width: 30px;
 height: 30px;
 padding: 2px 10px;
 font-size: 14px;
 border-radius: 25px;
 background-color: rgb(40 195 188);
 color: #ffffff;
 margin-bottom: 6px;
}
.main-container {
 border-top: 2px solid #707070;
 border-bottom: 2px solid #707070;
 padding: 5px;
 margin-top: 10px;
 width: 96%;
}

.content {
 display: flex;
 flex-direction: column;
}

p {
 margin: 0;
 font-size: 16px;
 font-family: "EuclidCircularB-Regular";
 color: #707070;
}

.box-container {
 display: flex;
 justify-content: flex-start;
 align-items: center;
 margin-top: 10px;
}

.box {
 width: 30px;
 height: 30px;
 background-color: #2bc7c980;
 margin-right: 5px;
}

/* bigger image  */

/* Style for the container that holds both the small image and the big image */
.image-container {
 position: relative;
 display: inline-block;
 margin-right: 10px;
}


.startRide-img-box {
    width: 60px;
    height: 60px;
    border-radius: 5px;
    margin-top: 0;
}

.crossIcon{
    position: absolute;
    right: -10px;
    top: -4px;
    width: 12px;
    height: 12px;
    background-color: gray;
    border-radius: 10px                    
}

/* Style for the small image */
.small-image {
 width: 75px; /* Set the desired small image size */
 height: 75px;
 margin-top: 10px;
 cursor: pointer;
 border-radius: 5px;
}

/* Style for the overlay */
.overlay {
 position: absolute;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 opacity: 0;
 pointer-events: none;
 transition: opacity 0.3s ease;
 background-color: rgba(0, 0, 0, 0.8); /* Overlay color */
 display: flex;
 justify-content: center;
 align-items: center;
 z-index:9999999
}

/* Style for the big image within the overlay */
.big-image {
 max-width: 300px; /* Set the desired bigger image size */
 max-height:300px;
 border: 2px solid #ffffff; /* Optional border for the big image */
 border-radius: 4px;
 pointer-events: none;
 z-index:9999999
}

/* Show the overlay and big image on hover */
.image-container:hover .overlay {
 opacity: 1;
}

/* Responsive adjustments for smaller screens */
@media screen and (max-width: 768px) {
 .dot-row {
  display: flex;
 }

 .connector {
  top: 0;
  left: 1.1%;
  height: 94%;
  width: 5px;
 }

 .headingTimeLine {
  display: none;
 }

 .content-heading-row {
  display: none;
  width: 100%;
  height: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
  /* margin: 20px 20px; */
 }
 .date-container-heading {
  width: 15%;
  padding-left: 10px;
 }
 .chip-created-heading {
  width: 20%;
 }
}

@media screen and (max-width: 420px) {
 .dot-row {
  display: flex;
 }

 .content-heading-row {
  display: none;
  width: 100%;
  height: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
 }

 .headingTimeLine {
  display: none;
 }

 .content-row {
  display: flex;
  width: 100%;
  height: auto;
 }

 .connector {
  top: 0;
  left: 2%;
  height: 94%;
  width: 5px;
 }
}
