.AddressCard {
  background-color: #053c3d !important;
  border: 1px solid;
  border-image-source: linear-gradient(90deg, #2bc7c9 0%, #01353d 45%);
  color: white;
  position: relative;
  cursor: pointer;
  border-radius: 8px !important;
  width: 220px;
  height: 120px;
}

.basicDetailsBox {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 20px;
}

.leadDetails {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.resetIconBox {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-bottom: 20px;
}

.resetIconApprovedOperator {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.sendAgreementButton {
  position: absolute;
  bottom: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 24px;
}

.generateAgreementContent {
  overflow-y: scroll;
  height: 70vh;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
}
