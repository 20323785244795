.accordian-wrap .nav-pills button {
  background-color: transparent;
}

.accordion-body {
  position: relative;
}

.accordian-wrap .nav-pills .nav-link.active,
.accordian-wrap .nav-pills .show>.nav-link,
.accordian-wrap .nav-pills .nav-link:hover {
  background-color: var(--light-green);
  border: solid 1px var(--light-green) !important;
  color: #fff !important;
}

.accordian-wrap .nav-pills .nav-item {
  margin: 0 10px 0 0;
  font-size: 0.875rem;
}

.accordian-wrap .nav.nav-pills {
  white-space: nowrap;
  padding-bottom: 10px !important;
}

h2 {
  font-size: var(--heading2);
}

.tab-scroller {
  max-width: 100%;
  overflow: hidden;
}

.tab-scroller-arrow {
  position: absolute;
  top: 24px;
  bottom: 0;
  width: 20px;
  cursor: pointer;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--dark-bg);
  height: 20px;
  color: #fff;
  border-radius: 100%;
}

.bi.bi-chevron-right,
.bi.bi-chevron-left {
  height: 20px;
  width: 20px;
  padding: 4px;
}

.tab-scroller .right-arrow {
  right: 0;
}

.tab-scroller .left-arrow {
  left: 0;
}

.tab-scroller .left-arrow.d-none~.nav {
  padding-left: 0;
}

.tab-scroller .nav {
  position: relative;
  padding-left: 35px;
  flex-wrap: nowrap;
}

.modal-content.model-content-wrap table .form-check-input:checked {
  background-color: #00353d;
  border-color: #00353d;
}

.modal-content.model-content-wrap table span.active {
  border: solid 1px #ccc;
  padding: 7px 10px;
  border-radius: 3px;
}

.modal-content.model-content-wrap .modal-header {
  border: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.modal-content.model-content-wrap .modal-body {
  padding: 10px 0;
}

.modal-content.model-content-wrap .table th {
  padding: 0.3rem 0.5rem;
}

/*------------ Payment-layout Css ---------------- */
table td,
table th {
  font-size: 0.875rem;
}

.table-dark th {
  background-color: var(--dark-green) !important;
  border: none !important;
}

.table-dark th:first-child {
  border-radius: 5px 0 0 0;
}

.table-dark th:last-child {
  border-radius: 0 5px 0 0;
}

.table-active {
  --bs-table-bg-state: #fbfbfb;
  border-color: transparent;
}

.table-active td {
  border: none !important;
}

.refund-green {
  background-color: var(--green-light);
  color: var(--text-green);
}

.refund-theme {
  background-color: var(--light-theme);
  color: var(--text-green);
}

.refund-blue {
  background-color: var(--blue-light);
  color: var(--text-blue);
}

.refund-box {
  padding: 4px 15px;
  margin: 0 auto;
  font-weight: 500;
  text-align: center;
}

.checkbox-wrap {
  padding: 4px 20px;
}

.checkbox-wrap .form-check-input:checked {
  background-color: var(--text-green);
  border-color: var(--text-green);
}

.checkbox-theme .form-check-input:checked {
  background-color: var(--light-green);
  border-color: var(--light-green);
}

.checkbox-blue .form-check-input:checked {
  background-color: var(--text-blue);
  border-color: var(--text-blue);
}

table {
  white-space: nowrap;
}

.table> :not(caption)>*>* {
  border-color: #efefef;
}

.execute-btn {
  vertical-align: middle;
  text-align: center;
  border-left: solid 1px #efefef;
}

.execute-btn a {
  background-color: var(--light-green);
  color: #fff;
  padding: 16px 48px;
  text-decoration: none;
}

.view-content table>tbody,
.view-content table>thead {
  border-width: 1px;
  border-bottom: 0;
}

.view-content .table-hover>tbody>tr:hover>* {
  background-color: #fffbfb !important;
  --bs-table-bg-state: transparent !important;
}

.view-content .table-hover>tbody>tr.active>* {
  background-color: #fffbfb;
}

.view-content .form-check {
  margin-bottom: 0;
}

.view-content a {
  text-decoration: underline;
  font-weight: 400;
}

.table-light th:first-child {
  border-radius: 5px 0 0 0;
}

.table-light th:last-child {
  border-radius: 0 5px 0 0;
}

.disable-table {
  border-top-color: transparent !important;
}

/* --------------------- Edit Booking-details Start---------------- */
.address-details-wrap {
  max-width: 800px;
  border: solid 1px #eee;
  background: rgb(248, 216, 112);
  background: linear-gradient(0deg,
      rgba(248, 216, 112, 0) 0%,
      rgba(255, 255, 255, 1) 100%);
}

.address-details-wrap h3 {
  font-size: 1rem;
  position: relative;
  padding-bottom: 8px;
  color: var(--dark-green);
  margin-bottom: 14px;
}

.address-details-wrap h3::after {
  position: absolute;
  content: "";
  width: 50px;
  height: 2px;
  background-color: var(--light-green);
  left: 0;
  bottom: 0;
}

.address-details-wrap h4 {
  color: var(--dark-green);
  font-weight: 400;
  font-size: var(--heading2);
  margin-bottom: 0;
}

.checkbox-theme .form-check-label {
  color: #707070;
  font-size: 14px;
  font-weight: 500;
}

.form-check-label.active {
  color: var(--dark-bg);
}

.form-check-label {
  color: #cacaca;
}

.num-helamte h4 {
  margin-right: 18px;
}

.num-wrap i {
  font-size: 8px;
  font-weight: 400;
  color: #707070;
}

.num-wrap .num {
  font-size: 1rem;
  padding: 2px 11px;
  background: #fff;
  border: solid 1px #eee;
  border-radius: 5px;
  box-shadow: 0px 1px 4px #0000000a;
  margin: 0 10px;
  color: #020c17;
}

.Helmete-options {
  border: solid 2px var(--light-green);
  max-width: 72%;
  padding: 10px;
  border-radius: 10px;
  margin: 10px 0;
  position: relative;
}

.Helmete-options img {
  margin-right: 20px;
  max-width: 28%;
}

.Helmete-options .details h5 {
  font-size: var(--heading2);
  margin-bottom: 0;
  font-weight: 400;
}

.Helmete-options .details p {
  margin: 0;
  color: #707070;
}

.Helmete-options.active::after {
  position: absolute;
  content: "\f00c";
  right: -10px;
  top: -10px;
  background: var(--light-green);
  width: 20px;
  height: 20px;
  border-radius: 100%;
  color: #fff;
  text-align: center;
  font: normal normal normal 14px/1 FontAwesome;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  border: solid 2px #fff;
}

/*-------------------- edit-booking details Css End ------------------*/

/*-------------------- Start login-screen -------------------- */
.login-wrapper {
  background-image: url(../../../assets/newAssets/images/login-bg.jpg);
  height: 100vh;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
}

.login-box {
  justify-content: space-between;
  position: relative;
}

.login-left {
  max-width: 480px;
}

.login-left img {
  position: absolute;
  top: 0;
  width: 180px;
  /* background-image: url(../../../assets/newAssets/images/admin-logo.png); */
}

.login-left h1 {
  font-size: 46px;
  font-weight: 400;
  color: #fff !important;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  padding-bottom: 3px;
}

.login-left h1::after {
  position: absolute;
  content: "";
  width: 66px;
  height: 4px;
  background-color: var(--light-green);
  left: 0;
  bottom: 0;
}

.login-left p {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 300;
}

.crediential-card {
  background: rgb(255, 255, 255);
  background: radial-gradient(circle,
      rgb(245, 255, 255) 60%,
      rgb(217, 249, 255) 100%);
  border-radius: 10px;
  padding: 50px 50px 0 50px;
  width: 560px;
  color: #00353d;
}

.crediential-card h2 {
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 40px;
  font-weight: 600;
}

.crediential-card input.form-control:focus {
  border-color: #2bc7c9;
}

.input-group:focus-within a {
  border-color: #2bc7c9;
}

.login-wrapper .container-fluid {
  padding: 0 30px;
  max-width: calc(1300px);
  width: 100%;
}

.crediential-card .form-label {
  font-size: 15px;
  margin-bottom: 6px;
}

.crediential-card .form-control {
  background-color: transparent;
  border: solid 1px #032226;
  padding: 16px 15px;
  font-size: 14px;
}

.copyright-txt {
  font-size: 14px;
  color: #fcfcfc;
  position: absolute;
  bottom: 0;
  left: 0;
}

.copyright-txt-right {
  display: none;
  font-size: 14px;
  color: #fcfcfc;
  text-align: center;
  margin: 10px 0 0 0;
}

.crediential-card .form-check {
  align-items: center;
}

.crediential-card .form-check-input {
  margin-right: 6px;
}

.crediential-card .form-check-label {
  color: #020c17;
  font-size: 14px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

a.forgot-password {
  color: #707070;
  font-size: 14px;
  text-decoration: underline;
}

a.forgot-password:hover {
  color: #2bc7c9;
  font-size: 14px;
}

.btn.login-btn {
  background-color: #00353d;
  margin: 40px 0 40px 0;
  color: #fff;
  padding: 15px;
  font-size: 18px;
}

.btn.login-btn:hover,
.btn.login-btn:focus {
  background-color: #2bc7c9;
  color: #fff;
}

.login-term {
  font-size: 12px;
  padding-bottom: 50px;
}

.login-term a {
  color: #46a984;
  text-decoration: underline;
}

.unlock-account {
  text-align: center;
  padding-bottom: 16px;
}

.unlock-account a {
  font-size: 14px;
  color: #00353d;
  text-align: center;
}

.unlock-account a:hover {
  text-decoration: underline;
}

.form-control.pw-input {
  margin: 0;
  border-right: 0;
}

.input-group-text {
  border-left: 0;
  border-color: #00353d;
  background-color: transparent;
}

.uname-fd {
  font-size: 18px;
  margin-bottom: 24px;
}

.pw-polic-card {
  position: absolute;
  top: 25%;
  bottom: 0;
  display: flex;
  align-items: center;
  left: 7%;
}

.pw-polic-card h6 {
  font-size: 14px;
  font-weight: 500;
}

.pw-polic-card .box {
  width: 550px;
  min-height: 290px;
  background-color: #f2f2f2;
  color: #00353d;
  padding: 15px;
  position: relative;
  margin: 0 40px;
  float: right;
  border-radius: 5px;
}

.pw-polic-card .box.arrow-right:after {
  content: " ";
  position: absolute;
  right: -15px;
  top: 15px;
  border-top: 15px solid transparent;
  border-right: none;
  border-left: 15px solid #f2f2f2;
  border-bottom: 15px solid transparent;
}

.pw-polic-card ul li {
  margin: 0 0 0 15px;
  font-size: 12px;
}

.pw-polic-card ul li span {
  font-weight: 600;
}

.btn.disable {
  background-color: #c6c6c6;
  opacity: 0.7;
  cursor: not-allowed;
}

.btn.disable:hover,
.btn.disable:focus {
  background-color: #c6c6c6;
  opacity: 0.7;
}

.fpw-text {
  font-size: 14px;
  background: #f9f9f9;
  border: solid 1px #ddd;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.fpw-text a {
  text-decoration: underline;
  font-weight: 500;
}

.error-message {
  color: #dc3545;
  font-size: 12px;
}

.error-input {
  border-color: #dc3545 !important;
}

.error-input::placeholder {
  color: #dc3545;
}

.stpw {
  color: #2bc7c9;
  font-size: 12px;
  white-space: nowrap;
}

.pw-status .progress {
  height: 8px;
}

.gdpw {
  color: #ffc107;
  font-size: 12px;
  white-space: nowrap;
}

.wepw {
  color: #dc3545;
  font-size: 12px;
  white-space: nowrap;
}

.close-icon-pw {
  display: none;
}

.pw-sucess {
  border-radius: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #2bc7c9;
  padding: 8px 25px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pw-sucess p {
  margin-right: 20px;
  margin-bottom: 0;
  color: #fff;
}

.pw-sucess img {
  filter: brightness(10) grayscale(0);
  width: 12px;
}

.pw-error {
  border-radius: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #c92b2b;
  padding: 8px 25px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pw-error p {
  margin-right: 20px;
  margin-bottom: 0;
  color: #fff;
}

.pw-error img {
  filter: brightness(10) grayscale(0);
  width: 12px;
}

/*-------------------- End login-screen -------------------- */
/* Change Password */
.change-password .login-left {
  display: none;
}

.change-password .login-right {
  margin: 0;
}

.change-password .login-wrapper {
  background-image: none;
}

.change-password .crediential-card {
  background: #fffbfb;
}

.change-password .pw-polic-card .box {
  width: 500px;
  min-height: 310px;
}

.change-password .pw-polic-card {
  position: absolute;
  top: 30%;
  bottom: 0;
  display: flex;
  align-items: center;
  left: 47%;
}

.change-password .pw-polic-card .box.arrow-right:after {
  content: " ";
  position: absolute;
  right: 100%;
  top: 55px;
  border-top: 15px solid transparent;
  border-left: none;
  border-right: 15px solid #f2f2f2;
  border-bottom: 15px solid transparent;
}

/* ====================== Responsive Payment Design ========================== */

@media (max-width: 576px) {

  .view-content table td,
  .view-content table th {
    font-size: 12px;
  }

  /*----------- edit-booking details Css start ---------------*/
  .booking-submit-btn button {
    width: 100%;
  }

  .address-details-wrap .theme-btn {
    margin-bottom: 10px;
  }

  .address-details-wrap {
    padding: 15px !important;
  }
}

/*---------- edit-booking details Css End ---------------*/

/* ------------ extend-ride css ---------------------*/

.package-wrap,
.hemlate-row,
.total-payment-row {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  border: solid 1px #ebebeb;
  max-width: 800px;
  margin: 20px auto;
}

.form-check {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 15px;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  /* position: absolute;
  left: -9999px; */
}



.form-check .form-check-input-payment{
  float: left;
  margin-left: -25px;
  margin-right: 9px;
  margin-top: 0px;
  z-index: -1;

}
.form-check-input {
  border: solid 1px #989898;
  z-index: 9999;
  left: 0;
}

.form-check .form-check-input {

  float: left;
  margin-left: -25px;
  margin-right: 10px;
  margin-top: 0.5px  !important;
  z-index: 0 !important;
  

}
.form-check .form-check-input-main{
  margin-left: -21px;

}

.form-check .form-check-edit-booking{
  margin-top: 4px  !important;
  /* float: left;
  margin-left: -25px;
  margin-right: 10px;
  
  z-index: 0 !important; */
}

.form-check-input:checked {
  background-color: var(--light-green);
  border-color: var(--light-green);
}

[type="radio"]:checked+label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 5px;
  width: 14px;
  height: 14px;
  border: 1px solid var(--light-green);
  border-radius: 100%;
  background: #fff;
}

[type="radio"]:checked+label::after,
[type="radio"]:not(:checked)+label::after {
  content: "";
  width: 8px;
  height: 8px;
  background: var(--light-green);
  position: absolute;
  top: 8px;
  left: 3px;
  border-radius: 100%;
  transition: all 0.2s ease;
}

[type="radio"]:checked+label::after {
  opacity: 1;
  transform: scale(1);
}

[type="radio"]:not(:checked)+label::before {
  content: "";
  position: absolute;
  /* left: 0;
  top: 5px;
  width: 14px;
  height: 14px;
  border: 1px solid #c4c4c4; */
  border-radius: 100%;
  background: #fff;
}

[type="radio"]:not(:checked)+label::after {
  opacity: 0;
  transform: scale(0);
}

.head-row {
  font-size: 18px;
  position: relative;
  padding: 0 0 5px 0;
  margin-bottom: 10px;
}

.head-row::after {
  position: absolute;
  left: 0;
  width: 30px;
  height: 2px;
  background-color: var(--light-green);
  content: "";
  bottom: 0;
}

.extend-ride-wrapper .form-select,
.extend-ride-wrapper .form-control {
  font-size: 14px;
  padding: 5px 9px;
  color: grey;
  border-radius: 5px;
}

.extend-ride-wrapper .form-label {
  font-size: 14px;
  font-weight: 400;
  width: 100%;
}

.Helmete-wrap .extra-head {
  font-size: 16px;
  font-weight: 400;
  margin-right: 20px;
}

.extra-Helmete {
  margin: 10px 20px 20px 0;
}

.extra-Helmete h4 {
  color: #707070;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0;
}

.extra-Helmete .checkbox-theme .form-check-label {
  color: #707070;
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
}

.helmete-option-row {
  display: flex;
}

.helmete-option-row .Helmete-options {
  border: solid 2px var(--light-green);
  max-width: 100%;
  padding: 10px;
  border-radius: 10px;
  margin: 0;
  position: relative;
}

.helmete-option-row .Helmete-options img {
  margin-right: 20px;
  max-width: 28%;
}

.helmete-option-row .Helmete-options.active {
  position: relative;
}

.helmete-option-row .Helmete-options.active::after {
  position: absolute;
  content: "\f00c";
  right: -10px;
  top: -10px;
  background: var(--light-green);
  width: 20px;
  height: 20px;
  border-radius: 100%;
  color: #fff;
  text-align: center;
  font: normal normal normal 14px/1 FontAwesome;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  border: solid 2px #fff;
}

.extra-Helmete h3 {
  font-size: 1rem;
  position: relative;
  padding-bottom: 0;
  color: var(--dark-green);
  margin-bottom: 3px;
}

.comp-hel-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
}

.coupon-row .form-control {
  margin-bottom: 0;
  border-radius: 5px 0 0 5px !important;
}

.extend-ride-wrapper p {
  margin-bottom: 10px;
  font-size: 14px;
  color: #c1c0c0;
}

.total-payment-row .form-check {
  font-size: 16px;
  margin-bottom: 14px;
}

.final-pay-row {
  margin: 20px 0;
  background: #f7f7f7;
  padding: 16px;
  border-radius: 5px;
}

.pay-step {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.pay-step h5 {
  font-size: 16px;
  margin: 0;
  display: flex;
  align-items: center;
}

.paybox {
  padding: 8px 21px;
  border-radius: 5px;
  border: solid 2px var(--light-green);
  color: var(--dark-bg);
}

.pwpolicyactive {
  position: relative;
  list-style: none;
  margin-left: 0 !important;
  padding-left: 20px !important;
  display: flex;
  align-items: center;
  color: #2bc7c9;
  font-weight: 600;
}

.pwpolicyactive span {
  padding-left: 1px;
}

.pwpolicyactive::after {
  list-style-type: none;
  color: #2bc7c9;
  position: absolute;
  content: "\f058";
  left: 0;
  border-radius: 100%;
  font: normal normal normal 16px/1 FontAwesome;
}

.pwpolicyactive::after {
  list-style-type: none;
  color: #2bc7c9;
  position: absolute;
  content: "\f058";
  left: 0;
  border-radius: 100%;
  font: normal normal normal 16px/1 FontAwesome;
}

@media (max-width: 360px) {
  .Helmete-wrap {
    flex-wrap: wrap;
  }

  .Helmete-wrap .extra-head {
    width: 100%;
    margin-bottom: 10px;
  }
}

/* ---------------- Login Responsive start ----------------*/
@media (max-width: 1200px) {
  .login-left {
    max-width: 430px;
  }

  .login-left h1 {
    font-size: 44px;
  }

  .login-left p {
    font-size: 18px;
  }

  .crediential-card {
    padding: 30px 30px 0 30px;
    width: 520px;
    color: #00353d;
  }
}

@media (max-width: 1100px) {
  .pw-polic-card .box {
    width: 425px;
  }
}

@media (max-width: 1024px) {
  .login-left h1 {
    font-size: 38px;
  }

  .login-left p {
    font-size: 16px;
  }

  .crediential-card h2 {
    font-size: 20px;
    margin-bottom: 30px;
  }

  .login-btn {
    margin: 30px 0 10px 0;
  }

  .login-term {
    padding-bottom: 40px;
  }
}

@media (max-width: 991px) {
  .login-left {
    margin-right: 20px;
  }

  .crediential-card {
    width: 455px;
    padding: 20px 20px 0 20px;
  }

  .login-left h1 {
    font-size: 36px;
  }

  .pw-polic-card .box {
    width: 374px;
  }

  .login-wrapper {
    height: auto;
    padding: 50px 0;
  }
}

@media (max-width: 860px) {
  .login-box {
    flex-wrap: wrap;
    justify-content: center;
  }

  .login-left img {
    position: static;
    width: 150px;
  }

  .login-left {
    margin-right: 0px;
    margin-bottom: 15px;
    width: 100%;
    text-align: center;
  }

  .login-left h1 {
    margin-bottom: 10px;
  }

  .login-left h1,
  .login-left p {
    display: none;
  }

  .crediential-card {
    max-width: 455px;
    width: 100%;
    padding: 30px 30px 0 30px;
  }

  .crediential-card .form-control {
    padding: 12px;
  }

  .crediential-card h2 {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .login-wrapper .container-fluid {
    padding: 0 15px;
  }

  .copyright-txt {
    left: 0;
    right: 0;
    bottom: -40px;
  }

  .copyright-txt {
    display: none;
  }

  .copyright-txt-right {
    display: block;
  }

  .pw-polic-card {
    position: absolute;
    top: 30%;
    right: 0;
  }

  .pw-polic-card .box {
    width: 100%;
    margin: 30px 26px;
  }

  .pw-polic-card .box.arrow-right::after {
    content: " ";
    position: absolute;
    right: 90%;
    top: -15px;
    border-top: none;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 15px solid #f2f2f2;
  }

  .pw-polic-card {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
  }

  .pw-polic-card .box.arrow-right::after {
    display: none;
  }

  .close-icon-pw {
    display: block;
    position: absolute;
    top: 5px;
    right: 5px;
    width: 25px;
    height: 25px;
    background: #fff;
    padding: 6px;
    border-radius: 100%;
  }

  .pw-sucess {
    top: 25%;
    height: 40px;
    right: 0;
    width: 289px;
    margin: 0 auto;
  }
}

/* ---------------- Login Responsive end ----------------*/