.lists-container {
 display: flex;
}

.list {
 flex-grow: 1;
 min-width: 200px;
 padding: 8px;
 border: 1px solid #ccc;
 border-radius: 4px;
 margin: 8px;
}

.horizontal-list {
 display: flex;

 flex-direction: row;
 flex-wrap: wrap;
}

.vertical-list {
 /* display: flex; */
 flex-direction: column;
 overflow-y: auto;
 /* max-height: 200px; */
}

.item {
 background-color: #f8f8f8;
 padding: 8px;
 margin: 8px 0;
 border: 1px solid #ccc;
 border-radius: 4px;
}

.horizontal-item {
 display: flex;
 align-items: center;
}

.vertical-item {
 display: block;
}

.tableBoxShadowRm {
 box-shadow: none;
}

.tableBorderRm {
 border: none;
 padding: 4px;
}

.tableHeadingText {
 color: #2bc7c9;
 font-family: EuclidCircularB-Medium;
 font-size: 13px;
}

.tableSerialNumber {
 color: #c6c6c6;
 font-family: EuclidCircularB-Regular;
 font-size: 13px;
}
.tableContentText {
 color: #020c17;
 font-family: EuclidCircularB-Regular;
 font-size: 13px;
}

.totalSlaMins {
 color: #020c17;
 font-family: EuclidCircularB-SemiBold;
 font-size: 14px;
 margin-top: 8px;
 margin-bottom: 8px;
 margin-left: 13px;
}
