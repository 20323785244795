.css-16tphzp-MuiBadge-badge {
  background-color: white;
  border-color: black;
  border: 1px solid black;
}

button {
  text-transform: none;
  /* color: white; */
}
