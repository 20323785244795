.bookingDetailsValue {
    font-weight: 400;
    font-size: 13px !important;
    font-family: Euclid Circular B !important;
    overflow: hidden;
    /* width: 100px; */
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .BoxRow {
    display: flex;
    flex-direction: row;
  }