/* Steps CSS */

.BoxColumn {
    display: flex;
    flex-direction: column;
}

.buttonGroup {
    width: 100px;
    text-transform: none !important;
    height: 30px;
}

.userDetails {
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    gap: 12px;
}



@media (max-width: 1000px) {
    .userDetails {
        flex-wrap: wrap;
    }
}

.deliveryDetails {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 30px
}

.buttonGroupDeliveryType {
    width: 150px;
    text-transform: none !important;
    height: 30px;
}

.pickUpTime {
    background-color: white;
    padding: 20px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
}

.paymentBreakup {
    gap: 10px;
    width: 30vw;
}

.dragDrop {
    height: 100px;
    width: 100px;
    border: 1px dashed black;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}



/* Plan Type Card CSS  Starts*/

.card {
    position: relative;
    cursor: pointer;
    border-radius: 16px !important;
}

.card.selected {
    background-color: #055455;
    color: #FFFFFFCC;
}

/* .card:not(.selected) {
    background-color: #055455;
} */

.planTypeCardBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
    padding: 9px 5px;
    width: 155px;
    /* background-color: #e5e5f7; */
}



.CheckoutBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    padding-top: 30px;
    /* padding: 32px 32px 32px 40px; */
}

.PlanTypeDetails {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 15px;
    gap: 10px;
    border-radius: 16px;
    border: 2px solid #EEEEEE;
    background-color: #01353D14;
}

.PlanTypeDetails.selected {
    border: none;
    background: #053C3D;

}

/* .detailsText {
    color: blue;
} */


/* Plan Type Card CSS  Ends*/


.checkUserForm {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 10px;
}

@media (max-width: 767px) {
    .CheckoutBox {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }
}

/* Home delivery Address card starts */

.AddressCard {
    position: relative;
    cursor: pointer;
    border-radius: 8px !important;
    width: 220px;
}

.AddressCard.selected {
    background: #053C3D;
    border: 1px solid;
    border-image-source: linear-gradient(90deg, #2BC7C9 0%, #01353D 45%);
    color: white;
}

.AddressCard:not(.selected) {
    border: 1px solid #B1B1B4;
    color: "#636369"
}

.AddressCardUI {
    display: flex;
    flex-direction: column;
    gap: 5px
}

.defaultIcon {
    display: flex;
    align-items: center;
    background:
        linear-gradient(90deg, rgba(1, 53, 61, 0.32) 0%, rgba(43, 199, 201, 0.32) 60%), linear-gradient(0deg, #FFFFFF, #FFFFFF);

    padding: 1px 8px 1px 8px;
    border-radius: 60px;
}


