body {
  margin: 0;
  font-family: "EuclidCircularB-SemiBold";
  src: local("EuclidCircularB-SemiBold"),
    url("./assets/fontFamily/EuclidCircularB-SemiBold.ttf") format("truetype");
  font-weight: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "EuclidCircularB-SemiBold";
  src: local("EuclidCircularB-SemiBold"),
    url("./assets/fontFamily/EuclidCircularB-SemiBold.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "EuclidCircularB-Bold";
  src: local("EuclidCircularB-SemiBold"),
    url("./assets/fontFamily/EuclidCircularB-Bold.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "EuclidCircularB-Medium";
  src: local("EuclidCircularB-Medium"),
    url("./assets/fontFamily/EuclidCircularB-Medium.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "EuclidCircularB-Regular";
  src: local("EuclidCircularB-Regular"),
    url("./assets/fontFamily/EuclidCircularB-Regular.ttf") format("truetype");
  font-weight: normal;
}

.inputRounded .MuiFilledInput-root {
  border-radius: 15px;
  min-height: 1.4375em;
  line-height: 0.5em;
  height: 2.5em;
}

div.pac-container {
  z-index: 2000 !important;
  width: 375px !important;
}

.rs-input-group.rs-input-group-inside {
  background-color: transparent !important;
}

.fc-col-header {
  background-color: #f3f3f3;
}

.fc .fc-daygrid-day-top {
  display: flex;
  flex-direction: row !important;
  margin-left: 10px;
  margin-top: 10px;
}

.fc-daygrid-dot-event:hover {
  background-color: unset !important;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: unset !important;
}

.fc .fc-daygrid-day.fc-day-today .background-todayDate {
  background-color: #053c3d !important;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  color: #ffffff;
  margin-left: -10px;
  align-items: center;
  margin-top: -7px;
}

@media screen and (max-width: 1000px) {
  .MuiPickersPopper-root {
    top: 300px !important;
  }
}

