.paymentDetailsPopUp {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
    border-radius: 12px !important;
    border-color: 1px solid black;
}


.BoxRow {
    display: flex;
    flex-direction: row;
}

.BoxColumn {
    display: flex;
    flex-direction: column;
}