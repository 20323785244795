.BoxRow {
  display: flex;
  flex-direction: row;
}

.BoxColumn {
  display: flex;
  flex-direction: column;
}

/* Plans card */
.card {
  position: relative;
  cursor: pointer;
  border-radius: 8px !important;
}

.card.selected {
  background-image: linear-gradient(
    109.51deg,
    #053c3d 27.34%,
    #0d3d3e 60.5%,
    #124546 93.8%
  );
  border: 1px solid #09cacc;
  color: white;
}

.card:not(.selected) {
  background-image: linear-gradient(
    111.31deg,
    #f0f6f7 0%,
    #f1f1f1 32.1%,
    #f7f8f8 49.56%,
    #fcfcfc 93.74%
  );
}

.planCardBox {
  display: flex;
  justify-content: center;
  gap: 24px;
  padding: 25px 10px;
}

/* helmet badge */

.badge {
  /* border: 1px solid #636369; */
  border: 1px solid #000;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  right: -5px;
  /* z-index: 1; */
  background-color: white;
  padding: 0px 7px !important;
}

.helmetSelectButton {
  min-height: 24px;
  max-height: 35px;
  width: 7vw !important;
  font-size: 12px !important;
  text-transform: none !important;
  color: #000 !important;
  border: 1px solid #000;
}

/* 
.helmetSelectButton::hover {
  border: 1px solid #1bd8da;
}
.badge::hover {
  border: 1px solid #1bd8da;
} */

.IncreasedSelectButton {
  display: flex;
  align-items: center;
  text-align: center;
  padding-left: 17px;
  padding-right: 17px;
  background: #1bd8da;
}

/* Select Box */

.select {
  border-radius: 8px !important;
  min-height: 38px;
  height: 5vh;
  max-height: 42px;
  margin: 0px;
}

.containerPadding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

/* Heading value Booking detail  */

.bookingDetailsHeading {
  font-family: Euclid Circular B !important;
  font-size: 12px !important;
  font-weight: 500;
}

.bookingDetailsValue {
  font-weight: 400;
  font-size: 13px !important;
  font-family: Euclid Circular B !important;
  overflow: hidden;
  /* width: 100px; */
  text-overflow: ellipsis;
  white-space: nowrap;
}

.badgeBlink {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  animation: Myblink 0.7s infinite;
}

.btnPopup {
  position: fixed;
  bottom: 0;
  background: #fff;
  padding: 5px;
}

@keyframes Myblink {
  0%,
  50% {
    width: 6px;
    height: 6px;
    background: #ff7a00;
  }
  100% {
    width: 6px;
    height: 6px;
    background: #eed659;
  }
}


/* PLAN DETAILS TOTAL CHARGE */
.totalChargeBox {
  display: flex;
  border: 1px dashed #1BD8DA;
  padding: 5px 16px 5px 16px;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
}

.amountBox {
  background:
      linear-gradient(270.37deg, rgba(43, 199, 201, 0.08) -1.53%, rgba(27, 196, 125, 0.08) 68.84%, rgba(24, 160, 251, 0.08) 101.83%);
  padding: 8px 16px 8px 16px;
  border-radius: 8px;
}
