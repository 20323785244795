.containerPadding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.autoCompleteInput {
  background-color: transparent;
  padding: 4px;
  border: 1px solid #b1b1b4;
  width: 98%;
  border-radius: 8px;
  min-height: 38px;
  height: 5vh;
  max-height: 42px;
  margin: 0px;
  padding-left: 14px;
  box-shadow: 1px 1px 2px 0px #00000021 inset, 11px 10px 32px 0px #0000000a,
    -19px -7px 32px 0px #3333330a;
}

.App {
  font-family: sans-serif;
  text-align: center;
  height: 40vh;
}

.AppMap {
  height: 100%;
  width: 100%;
  /* border-radius: 10px; */
}

h1,
p {
  font-family: Lato;
}
/**
   * The geosuggest module
   * NOTE: duplicated font-sizes' are for browsers which don't support rem (only IE 8)
   */
.geosuggest {
  font-size: 18px;
  font-size: 1rem;
  position: relative;
  width: 50%;
  margin: 1em auto;
  text-align: left;
}
.geosuggest__input {
  width: 100%;
  border: 2px solid transparent;
  box-shadow: 0 0 1px #3d464d;
  padding: 0.5em 1em;
  -webkit-transition: border 0.2s, box-shadow 0.2s;
  transition: border 0.2s, box-shadow 0.2s;
}
.geosuggest__input:focus {
  border-color: #267dc0;
  box-shadow: 0 0 0 transparent;
}
.geosuggest__suggests {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 25em;
  padding: 0;
  margin-top: -1px;
  background: #fff;
  border: 2px solid #267dc0;
  border-top-width: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  z-index: 5;
  -webkit-transition: max-height 0.2s, border 0.2s;
  transition: max-height 0.2s, border 0.2s;
}
.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

/**
   * A geosuggest item
   */
.geosuggest__item {
  font-size: 18px;
  font-size: 1rem;
  padding: 0.5em 0.65em;
  cursor: pointer;
}
.geosuggest__item:hover,
.geosuggest__item:focus {
  background: #f5f5f5;
}
.geosuggest__item--active {
  background: #267dc0;
  color: #fff;
}
.geosuggest__item--active:hover,
.geosuggest__item--active:focus {
  background: #ccc;
}
.geosuggest__item__matched-text {
  font-weight: bold;
}

.hubShadowEffect {
  border-radius: 10px;
   box-shadow: 0px 3px 4px 2px #0000000D
}
