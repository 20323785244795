.AppSwitcher ul {
  display: flex;
}

.MuiMenu-paper {
  padding: 10px;
}

.lastLoginLabels {
  font-size: 0.5rem;
}

.headerIconsContainer {
  display: flex;
  align-items: center;
  gap: 20px;
}

.headerIcons {
  /* padding: 6px;
  border-radius: 50%; */
  background: linear-gradient(
    270deg,
    rgba(43, 199, 201, 0.16) -1.53%,
    rgba(27, 196, 125, 0.16) 68.84%,
    rgba(24, 160, 251, 0.16) 101.83%
  );
  height: fit-content;
}

.MuiAvatar-circular {
  width: "6vw";
}

/* SIDE-DRAWER */
.brandLogoContainer {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  margin: "8px";
}
.brandLogo {
  height: 40px !important;
}

.activeParentModuleDrawerLTR {
  color:#fff;
  border-radius: 8px;
  background: #053C3D;
}

.activeParentModuleYOR {
  background: linear-gradient(
    270deg,
    rgba(27, 216, 218, 0.24) 1.11%,
    rgba(27, 196, 125, 0.24) 156.03%
  );
  border-radius: 8px;
}


.DrawerToggleBtnExpanded {
  position: absolute !important;
  left: -4px;
  top: 35px;
  border-radius: 50%;
  background: var(
    --Gradient-Prim-sec,
    linear-gradient(90deg, #1bd8da 1.67%, rgba(27, 196, 166, 0.95) 123.92%),
    #fff
  );
  padding: 4px !important;
}

.DrawerToggleBtnCollapsed {
  position: relative;
  top: 35px;
  background: var(
    --Gradient-Prim-sec,
    linear-gradient(90deg, #1bd8da 1.67%, rgba(27, 196, 166, 0.95) 123.92%),
    #fff
  );
  padding: 4px !important;
  left: -58px;
}
/* .DrawerToggleButton::hover .hoverDrawerToggleIcon{
  display: inline;
}
.DrawerToggleButton::hover .drawerToggleBarIcon {
  display: none;
} */

/* SIDE-DRAWER ENDS */
/* 
.logoutButtonX {
  background: var(
    --red-gradient-10,
    linear-gradient(90deg, #ff3b30 -1.26%, #f3756a 100%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border-radius: 100px;
  border: 1px solid #ff3b30;
} */

.logoutIconX::hover {
  color: "#fff";
}

/* .MuiMenu-paper .profileDropdownX {
  border-radius: 0px 0px 0px 16px;
  border-right: 2px solid rgba(0, 0, 0, 0.08);
  border-bottom: 2px solid rgba(0, 0, 0, 0.08);
  border-left: 2px solid rgba(0, 0, 0, 0.08);
  background: linear-gradient(
    180deg,
    rgba(251, 251, 251, 0.8) 0%,
    rgba(255, 255, 255, 0.8) 100%
  );
  box-shadow: 0px 16px 64px 0px rgba(0, 0, 0, 0.16);
  backdrop-filter: blur(120px);
} */

.css-hfvp59-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper
  .profileDropdownX {
  border-radius: "0px 0px 0px 16px !important";
  border-right: 2px solid rgba(0, 0, 0, 0.08);
  border-bottom: 2px solid rgba(0, 0, 0, 0.08);
  border-left: 2px solid rgba(0, 0, 0, 0.08);
  background: linear-gradient(
    180deg,
    rgba(251, 251, 251, 0.8) 0%,
    rgba(255, 255, 255, 0.8) 100%
  );
  box-shadow: 0px 16px 64px 0px rgba(0, 0, 0, 0.16);
  backdrop-filter: blur(120px);
}

.MuiPaper-root .MuiMenu-paper .MuiPopover-paper .profileDropdownX {
  border-radius: "0px 0px 0px 10px !important";
}

.profileDropdownXCustom {
  position: absolute;
  top: 63px;
  background: #fff;
  border-radius: 0px 0px 0px 16px;
  backdrop-filter: blur(120px);
  width: 15vw;
  right: 15px;
}

.UserDetailWrapper {
  padding: 10px 10px 10px 10px;
  /* background: #8585853d; */
  /* gap: 8px; */
}
.ProfileActionWrapper {
  padding: 5px 10px 5px 10px;
}

.UserDetailWrapperCustom {
  padding: 24px 16px 16px 16px;
  background: #8585853d;
  gap: 8px;
}

.ProfileLastSeenCustom {
  justify-content: space-between;
}

.ProfileActionWrapperCustom {
  padding: 10px 16px 16px 16px;
}

.DrawerToggleButton :nth-child(2) {
  display: none;
}

.DrawerToggleButton:hover .drawerToggleBarIcon {
  display: none;
}

.DrawerToggleButton:hover .hoverDrawerToggleIcon {
  display: block;
}

