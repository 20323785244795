/* input field stying */
.input-container {
  padding: 3px 10px !important;
  background-color: rgb(242, 242, 242);
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  align-items: center;
  margin-top: 3px;
}

.input-wrapper {
  min-width: 300px;
  margin-right: 25px;
}

.small-input {
  min-width: 100px;
  margin-right: 5px;
}

.input-container .input {
  background-color: rgb(233, 240, 253);
  width: 100%;
  margin-left: 5px;
  border-radius: 12px;
  justify-content: center;
  padding: 5px 10px;
  border: 2px solid rgb(233, 240, 253);
}

.input-container .input-fix {
  background-color: rgb(242, 242, 242);
  border-radius: 12px;
  justify-content: center;
  /* padding: 5px 10px; */
  padding-left: 5px;
  border: 2px solid rgb(242, 242, 242);
}

.input-container .input-focus {
  border: 2px solid rgb(255, 255, 255);
}

.input-error {
  border: 1px solid rgb(244, 8, 8);
}

.drawer-icon {
  display: none;
}

.date-picker {
  background-color: rgb(242, 242, 242);
  padding: 10px;
  border-radius: 10px;
}

.heading-container {
  margin: 15px 0px;
}

.heading-buttons {
  display: flex;
  align-items: center;
}

.logo {
  height: 40px !important;
}

.gradient-card {
  height: 110px;
  width: 220px;
  border-radius: 20px;
  margin: 0px 15px 25px 0px;
}
.dragDrop-card {
  /* height: auto; */
  min-height: 200px;
  width: 200px;
  box-shadow: 2px 4px 8px #dbdbdb80;
  border: 1px solid #dddddd;
  border-radius: 16px;
  /* margin: 0px 10px 25px 0px; */
  padding: 8px 5px 9px 15px;
}
.dragDrop-card-Ride {
  min-height: 180px;
  width: 210px;
  /* box-shadow: 2px 4px 8px #dbdbdb80; */
  box-shadow: 2px 4px 8px #2bc7c980;
  border: 1px solid #dddddd;
  border-radius: 16px;
  margin: 0px 15px 25px 0px;
  padding: 8px 5px 9px 15px;
}

.dragDropRightSideScroll {
  height: 550px;
  overflow: auto;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.dragDropRightSideScroll::-webkit-scrollbar {
  width: 0.2em;
  height: 0.3em;
}

.dragDropRightSideScroll::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  /* border-radius: 3px; */
}

.dragDropRightSideScroll::-webkit-scrollbar-track {
  /* background-color: rgba(0, 0, 0, 0.1); */
  background-color: #2bc7c980;
  /* border-radius: 3px; */
}

.dragDropVerticalList {
  box-shadow: 2px 4px 8px #dbdbdb80;
  border: 1px solid #dddddd;
  border-radius: 8px;
  padding: 20px;
  height: 390px;
  overflow: auto;
}

.dragDropVerticalList::-webkit-scrollbar {
  width: 0.2em;
  height: 0.3em;
}

.dragDropVerticalList::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

.dragDropVerticalList::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}

.block-ellipsis {
  display: block;
  display: -webkit-box;
  margin: 0 auto;
  font-size: 14px;
  line-height: 1;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.CardPopupStyle {
  position: absolute;
  left: 26%;
  margin-top: -12px;
  z-index: 999;
  transition: all 0.5s ease-in-out 3s;
}
.CardPopupStyle:after {
  content: " ";
  position: absolute;
  left: -15px;
  top: 15px;
  border-top: 15px solid transparent;
  border-right: 15px solid #00353d;
  border-left: none;
  border-bottom: 15px solid transparent;
}

.semi-circle {
  height: 110px;
  width: 90px;
  border-radius: 0 100px 100px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-select-taskManagement.MuiOutlinedInput-root {
  border: none;
  outline: none;
  border-radius: 24px;
}

.custom-select-taskManagement.MuiOutlinedInput-root:hover {
  border: none;
  border-radius: 24px;
}

.custom-select-taskManagement.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: none;
  outline: none;
  border-radius: 24px;
}

/* .divider {
  height: 25px;
  background-color: rgb(227, 227, 227);
} */

.brown {
  background-image: linear-gradient(rgb(217, 191, 172), rgb(165, 165, 144));
}

.sub-brown {
  background-image: linear-gradient(rgb(200, 177, 160), rgb(150, 150, 125));
}

.blue {
  background-image: linear-gradient(rgb(96, 207, 213), rgb(80, 176, 217));
}

.sub-blue {
  background-image: linear-gradient(rgb(87, 190, 195), rgb(73, 160, 200));
}

.pink {
  background-image: linear-gradient(rgb(236, 197, 248), rgb(214, 172, 250));
}

.sub-pink {
  background-image: linear-gradient(rgb(227, 192, 238), rgb(192, 147, 230));
}

.yellow {
  background-image: linear-gradient(rgb(250, 218, 113), rgb(250, 200, 67));
}

.sub-yellow {
  background-image: linear-gradient(rgb(235, 200, 85), rgb(200, 166, 62));
}

.date-picker-container {
  margin-bottom: 5px;
}

.drawer-list {
  display: flex;
  align-items: center;
  /* padding: 25px 0px 0px 20px; */
  margin: 10px 10px 0px 10px;
  padding: 10px;
  border-radius: 12px;
  width: 100%;
  cursor: pointer;
}

.mini-drawer-list {
  display: flex;
  align-items: center;
  /* padding: 5px; */
  /* margin: 5px 20px; */
  padding: 5px;
  border-radius: 12px;
  width: 100%;
  cursor: pointer;
  background-color: aqua;
}

.drawer-list:hover {
  background-color: black;
  -webkit-transition: background 2s;
  /* For Safari 3.0 to 6.0 */
  transition: background 2s;
}

.drawer-selected {
  background-color: black;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0px;
  background-color: lightgray;
  margin: 2px;
  cursor: pointer;
  border-radius: 8px;
  transition: transform 1s;
  /* Animation */
}
.tabTask {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0px;
  /* background-color: lightgray; */
  margin: 2px;
  cursor: pointer;
  border-radius: 10px;
  transition: transform 1s;
  /* Animation */
}

.tab:hover {
  /* background-color: var(--theme); */
  -webkit-transition: background 1s;
  /* For Safari 3.0 to 6.0 */
  transition: background 1s;
  border-radius: 8px;
  transform: scale(1.05);
}

.tab-active {
  background-color: var(--theme);
}
.tabTask-active {
  background-color: #528e8a;
  color: #ffff;
}
.tabTaskBottomLine {
  width: 15%;
  height: 2px;
  background-color: var(--theme);
}
.tabSelect {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  cursor: pointer;
  transition: transform 1s;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dddddd;
  border-radius: 8px;
  /* Animation */
}

.tabSelect:hover {
  background-color: #fefafa;
  -webkit-transition: background 1s;
  /* For Safari 3.0 to 6.0 */
  transition: background 1s;
  border-radius: 8px;
  transform: scale(1);
}

.tabSelect-active {
  background-color: var(--theme);
  border: 1px solid var(--theme);
}

.SelectTabDropDownContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.SelectTabDropDown {
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  padding: 8px 0px;
  /* background-color: rgb(247, 247, 247); */
  border: 1px solid #d3d3d3;
  cursor: pointer;
  border-radius: 3px;
  transition: transform 1s;
  /* Animation */
}

.SelectTabDropDown:hover {
  background-color: #eceaea;
  -webkit-transition: background 1s;
  /* For Safari 3.0 to 6.0 */
  transition: background 1s;
  border-radius: 8px;
  transform: scale(1.05);
}

.SelectTabDropDown-active {
  background-color: var(--theme);
  border: 1px solid var(--theme);
}

@media (max-width: 1024px) {
  .drawer-icon {
    margin-right: 15px;
    display: flex;
  }
}

@media (max-width: 767px) {
  .static-drawer {
    display: none;
  }

  .gradient-card {
    height: 100px;
    width: 47%;
    margin: 5px;
  }

  .semi-circle {
    height: 100px;
    width: 80px;
    border-radius: 0 100px 100px 0;
  }

  .date-picker {
    margin-bottom: 10px;
  }

  .divider {
    height: 15px;
    margin-top: 15px;
  }

  .input-wrapper {
    min-width: "100%";
    margin-right: 0px;
  }

  .logo {
    height: 30px !important;
  }

  .tab {
    padding: 6px 0px;
  }
}

@media (min-width: 767px) {
  .swipe-drawer {
    display: none;
  }
}

.inner-box {
  min-width: 50%;
  /* height: 50px; */
  border: 1px solid black;
  display: flex;
  justify-content: center;
  padding: 15px 0px;
}

.Rentaltable {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  /* align-content: flex-start; */
}
.Rentalsecondtable {
  width: 100%;
  overflow: auto;
}

@-moz-keyframes spin {
  to {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 1000ms linear;
}

.otpContainer {
  margin: 5% auto;
  display: flex;
  padding: 10px;
  justify-content: center;
}

.otpInput {
  width: 2rem !important;
  height: 2rem;
  margin: 2px 10px;
  font-size: 0.9rem;
  border-radius: 4px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.tab-text {
  color: white !important;
}

/* Unset Autofill */
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s !important;
}

input:-internal-autofill-selected {
  background-color: unset !important;
}

/* .css-do0wal-MuiPaper-root-MuiAppBar-root{
  z-index: 0 !important;
} */

.disabled-icon {
  color: grey;
  pointer-events: none;
  opacity: 0.5;
}

.MuiDataGrid-virtualScroller {
  border-radius: 16px;
}
