.input-range-picker input {
    background-color: transparent !important;
    padding: 4px;
    border: 1px solid #B1B1B4;
    width: 100%;
    border-radius: 8px !important;
    min-height: 38px;
    height: 5vh;
    max-height: 42px;
    margin: 0px;
    padding-left: 14px;
    box-shadow: 1px 1px 2px 0px #00000021 inset,11px 10px 32px 0px #0000000A, -19px -7px 32px 0px #3333330A;
    padding: 0px !important;

}

.react-datepicker__close-icon::after {
    background-color: #1bd8da;
}