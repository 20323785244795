/* TOP CARDS START */

.cardContainer {
  display: flex;
  /* border: 1px solid #c7e9e9; */
  background:
    linear-gradient(0deg, rgba(56, 134, 145, 0.12), rgba(56, 134, 145, 0.12)),
    linear-gradient(0deg, #FFFFFF, #FFFFFF);

  padding: 30px 12px;
  border-radius: 16px;
  gap: 22px;
  align-items: center;
  width: 50%;
  min-width: 390px;
  max-width: 500px;
}

.detailsBlock {
  display: flex;
  align-items: center;
  gap: 5px;
}

.topCardsimg {
  position: absolute;
  top: 0;
  right: 0;
  width: 134px,
}

.modelImageContainer {
  width: 12vw;
  height: 14vh;
  background-position: center;
  /* background-size: cover; */
  background-repeat: no-repeat;
  border-radius: 1vw;
}
/* TOP CARDS ENDS */