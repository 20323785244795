/* TOP CARDS START */

.cardContainer {
  display: flex;
  border: 1px solid #c7e9e9;
  background: linear-gradient(270.37deg,
      rgba(43, 199, 201, 0.04) -1.53%,
      rgba(27, 196, 125, 0.04) 68.84%,
      rgba(24, 160, 251, 0.04) 101.83%);
  padding: 21px 12px;
  border-radius: 16px;
  gap: 22px;
  align-items: center;
  width: 50%;
  min-width: 390px;
  max-width: 500px;
}

.detailsBlock {
  display: flex;
  align-items: center;
  gap: 5px;
}

.topCardsimg {
  position: absolute;
  top: 0;
  right: 0;
  width: 134px,
}

.modelImageContainer {
  width: 12vw;
  height: 14vh;
  background-position: center;
  /* background-size: cover; */
  background-repeat: no-repeat;
  border-radius: 1vw;
}

/* TOP CARDS ENDS */