.fc .fc-daygrid-day-top {
  background-color: #b1b1b1;
}

.calendarMonths {
  position: absolute;
  top: 239px;
  left: 399px;
}

.dayHeaderContent {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f3f3;
}

.fullCalendarHeader {
  height: 55px;
  background-color: #01353d29;
  width: 100%;
  border-radius: 7px 7px 0px 0px;
  padding: 10px 24px;
  display: flex;
  justify-content: space-between;
}

.fullCalendarHeaderEnd {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 17px;
  align-items: center;
}
