* {
  font-family: "Euclid Circular B";
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Euclid Circular B";
  color: #020c17;
}

.scroller {
  scrollbar-width: thin;
}
/* changed to remove scrollbar from drawer as this overrides scrollbar css */
::-webkit-scrollbar {
  width: 0em;
}

::-moz-scrollbar {
  width: 0.5em;
}

::-webkit-scrollbar-button {
  background: #ccc;
}

::-webkit-scrollbar-track-piece {
  background: #ccc;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

:root {
  --dark-green: #00353d;
  --dark-green2: #006063;
  --light-green: #2bc7c9;
  --light-theme: #e6fffd;
  --green-light: #e7f3ef;
  --text-green: #009b66;
  --blue-light: #e7ebf2;
  --text-blue: #013287;
  --blue-light2: #e7f3ef;
  --text-blue2: #00353d;
  --white: #fff;
  --body-bg: #fbfbfb;
  --dark-bg: #020c17;
  --nav-text-size: 1rem;
  --main-content-margin: 30px 40px;
  --heading1: 1.125rem;
  --heading2: 0.938rem;
}

a {
  color: var(--light-green);
  text-decoration: none;
}

th {
  font-weight: 500;
}

th.head-l-green {
  background-color: var(--green-light);
  color: var(--text-green);
}

th.head-l-blue {
  background-color: var(--blue-light);
  color: var(--text-blue);
}

th.head-l-blue2 {
  background-color: var(--blue-light2);
  color: var(--text-blue2);
}

.form-check-input:focus {
  box-shadow: none;
}

.table-model-btn {
  background-color: var(--light-green);
  color: #fff;
}

.table-model-btn:hover {
  background-color: var(--light-green);
  color: #fff;
  box-shadow: 0 3px 6px #2bc6c94f;
}

ul {
  margin: 0 !important;
  padding: 0 !important;
}

body {
  min-height: 100vh;
  overflow-x: hidden;
}

.dbcontainer {
  position: relative;
  width: 100%;
}

.btn {
  border: none !important;
  box-shadow: none;
}

.form-control:focus,
.form-select:focus {
  box-shadow: none !important;
  border-color: var(--light-green);
}

/* =============== Navigation ================ */
.navigation {
  position: fixed;
  width: 300px;
  height: 100%;
  background: var(--dark-green);
  border-left: 10px solid var(--blue);
  transition: 0.5s;
  overflow: hidden auto;
}

.navigation.active {
  width: 60px;
}

.navigation ul {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.navigation ul li {
  position: relative;
  width: 100%;
  list-style: none;
  padding-left: 14px;
  font-size: var(--nav-text-size);
}

.navigation ul li:nth-child(1) {
  margin-bottom: 12px;
  pointer-events: none;
  padding-left: 4px;
}

.brand a {
  margin: 40px 0;
  display: block;
  color: var(--white);
  font-size: 1.5rem;
  text-decoration: none;
}

.navigation ul li a {
  display: flex;
  text-decoration: none;
  color: var(--white);
}

.navigation ul li a .icon {
  display: flex;
  text-align: center;
  align-items: center;
  margin-right: 14px;
}

.navigation ul li a .icon ion-icon {
  font-size: 1.5rem;
}

.navigation ul li a .title {
  position: relative;
  display: block;
  padding: 0 0px;
  height: 50px;
  line-height: 50px;
  text-align: start;
  white-space: nowrap;
}

.operator-title {
  font-size: 0.75rem;
  border-left: solid 1px #fff;
}

/* ===================== Main ===================== */
.main {
  position: absolute;
  width: calc(100% - 300px);
  left: 300px;
  min-height: 100vh;
  background: var(--body-bg);
  transition: 0.5s;
}

.main.active {
  width: calc(100% - 60px);
  left: 60px;
}

.main.active .topbar-menu ul {
  list-style-type: none;
  margin-left: 15px !important;
}

.navigation.active li a .title {
  padding-left: 60px;
  transition: 0.5s;
}
/* 
.topbar {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background-color: var(--white);
  padding-right: 20px;
}

.topbar-left {
  position: relative;
}

.topbar-right img {
  padding: 0 10px;
} */

.noti-wrap {
  position: relative;
}

.noti-wrap .noti-alert {
  position: absolute;
  top: 1px;
  background-color: var(--light-green);
  width: 10px;
  height: 10px;
  border-radius: 100%;
  right: 8px;
  border: solid 2px #fff;
}

.dropdown-menu {
  transform: translate(0px, 60px) !important;
  border-radius: 20px 0 20px 20px;
  padding: 8px 10px !important;
  right: 10px !important;
}

.dropdown-menu li {
  border-bottom: solid 1px #ddd;
  padding: 8px 0;
}

.dropdown-menu li:last-child {
  border: navajowhite;
}

.dropdown-menu::before,
.dropdown-menu::after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  border-left: 11px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 18px solid #999;
  top: -15px;
  right: 20px;
}

.dropdown-menu::before {
  top: -19px;
  left: 201px;
  border-bottom-color: #999999;
}

.dropdown-menu::after {
  top: -18px;
  left: 201px;
  border-bottom-color: #ffffff;
}

.dropdown-menu li a span {
  color: var(--light-green);
}

.toggle {
  position: absolute;
  left: -26px;
  cursor: pointer;
  display: flex;
  justify-content: start;
  align-items: center;
  top: -7px;
}

.logo {
  position: relative;
  overflow: hidden;
  margin-top: 5px;
  margin-bottom: 10px;
  text-align: center;
}

.logo img {
  width: 130px;
}

.navigation ul li a .icon.DRlogo {
  width: 50px;
  min-height: 50px;
  background-color: var(--white);
  color: var(--black1);
  border-radius: 100%;
  line-height: 50px !important;
  font-size: 1.25rem !important;
  margin-right: 10px;
}

.topbar-menu {
  font-weight: 500;
  margin: 0 0 0 30px;
}

.topbar-menu ul {
  list-style-type: none;
}

.topbar-menu ul li {
  font-size: var(--nav-text-size) !important;
}

.topbar-menu ul li a {
  color: var(--dark-green2);
  padding: 0 10px;
  font-weight: 400;
}

.main-content-wrap {
  margin: var(--main-content-margin);
}

.main-content-wrap h1 {
  font-size: var(--heading1);
}

nav .breadcrumb li {
  font-size: 0.835rem;
}

nav .breadcrumb li a {
  text-decoration: none;
  color: var(--light-green);
}

.accordian-wrap .accordion-button:focus {
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}

.accordian-wrap .accordion-button:not(.collapsed) {
  background-color: transparent;
  box-shadow: 4px 4px 11px 0 #ececec;
  border-bottom: solid 1px #2bc7c9;
  border-radius: 0;
}

.accordian-wrap .accordion-item {
  box-shadow: 4px 4px 11px 0 #ececec;
  border-radius: 10px;
  border: none;
}

.accordian-wrap .accordian-tabcontent .accordion-item button {
  border-radius: 10px;
}

.accordian-wrap .accordion-button::after {
  background-image: url(../../../assets/newAssets/images/arrow-accordian-up.png);
}

.accordian-wrap .accordion-button:not(.collapsed)::after {
  background-image: url(../../../assets/newAssets/images/arrow-accordian.png);
  transform: rotate(0deg);
}

.w-30 {
  width: 30%;
}

.w-70 {
  width: 70%;
}

.booking-info-wrap .left {
  background-color: #f9feff;
}

.booking-info-wrap li {
  list-style-type: none;
  border-bottom: solid 1px #f2f1f1;
  padding: 6px 18px;
  font-size: 0.875rem;
}

.booking-info-wrap .right {
  background-color: #f8f8fa;
}

.tab-scroller-arrow {
  width: 30px;
  background-color: #ffffff;
  border: none;
  height: 30px;
}

/* ------------------- New Css 26-7-2023 -------------------*/
.theme-btn {
  background-color: var(--light-green);
  color: #fff;
  padding: 6px 20px;
  text-decoration: none;
  font-size: 14px;
  white-space: nowrap;
  margin-right: 10px;
}

.theme-btn:hover {
  background-color: var(--light-green);
  color: #fff;
}

.theme-btn-outline {
  border: solid 1px var(--light-green);
  color: var(--light-green);
  padding: 6px 20px;
  text-decoration: none;
  font-size: 14px;
  white-space: nowrap;
  margin-right: 10px;
  background-color: transparent;
}

.form-control,
.form-select {
  margin-bottom: 13px;
  font-size: 12px;
  padding: 9px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: #020c17;
  margin-bottom: 0;
}

/* ====================== Responsive Design ========================== */
@media (max-width: 991px) {
  :root {
    --nav-text-size: 0.875;
    --main-content-margin: 20px 20px;
    --heading1: 1.125rem;
  }

  .navigation {
    left: -300px;
  }

  .navigation.active {
    width: 300px;
    left: 0;
  }

  .navigation.active li a .title {
    padding-left: 6px;
  }

  .main {
    width: 100%;
    left: 0;
  }

  .main.active {
    left: 300px;
  }

  .toggle {
    left: 0;
  }

  .topbar-menu {
    margin: 0 0 0 45px;
  }
}

@media (max-width: 767px) {
  .w-md-50 {
    width: 50% !important;
  }
}

@media (max-width: 576px) {
  .w-sm-100 {
    width: 100% !important;
  }
}

@media (max-width: 480px) {
  .user {
    min-width: 40px;
  }

  .navigation {
    width: 100%;
    left: -100%;
    z-index: 1000;
  }

  .navigation.active {
    width: 100%;
    left: 0;
  }

  .main.active .toggle {
    color: #fff;
    position: fixed;
    right: 0;
    left: initial;
    z-index: 99999;
    top: 5px;
  }
}

@media (max-width: 360px) {
  .toggle {
    top: 0;
  }
}
