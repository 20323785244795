.pd-25 {
  padding: 3.5vh;
}
.pd-20 {
  padding: 3vh;
}
.pd-15 {
  padding: 1.5vh;
}
.pd-10 {
  padding: 1vh;
}

.pdb-25 {
  padding-bottom: 25px;
}

.mr-10 {
  margin-right: 10px;
}
.mr-15 {
  margin-right: 15px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-25 {
  margin-right: 25px;
}

.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-23 {
  margin-top: 23px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-50 {
  margin-top: 50px;
}

.ml-10 {
  margin-left: 10px;
}
.ml-15 {
  margin-left: 15px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-25 {
  margin-left: 25px;
}
.ml-50 {
  margin-left: 50px;
}
.ml-100 {
  margin-left: 100px;
}
