.addressSelection {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 15px;
  justify-content: space-between;
}

.scrollBox {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  margin-right: 12px;
}

.CardListContainer {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  white-space: nowrap;
  scroll-behavior: smooth;
  width: 39vw;
  padding-right: 45px;
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media (max-width: 1136px) {
  .CardListContainer {
    width: 60vw;
  }
}

.CardListContainer > * {
  flex: 0 0 auto;
  margin-right: 10px;
  scroll-snap-align: start;
  /* Optional for snap scrolling */
}
