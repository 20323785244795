.aggreementContainer {
    width: fit-content;
    cursor: pointer;
}

.aggreementThumbnail {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
    position: relative;
}

.imageText {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    top: 50px;
    left: 90px;
    color: #2ABDBD
}

.aggreementContainer:hover .aggreementThumbnail {
    opacity: 0.3;
}

.aggreementContainer:hover .imageText {
    opacity: 1;
}