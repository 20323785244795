.boxRow{
    display: flex;
    flex-direction: row;
    gap:16px;
    align-items: center;
}

.BoxColumn {
    display: flex;
    flex-direction: column;
    gap:20px
}
.tableHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.listTable{
    width: 135px;
    height: 40px;
    border-radius: 8px !important;
    transform: translate(0,15%);
    top: 50%,
}

/* wareHouse Name */
.textOverflow{
    font-size:14px !important;
    font-weight:500 !important;
    color:#3C3C43;
    overflow: hidden;
    width: 100px;
    text-overflow: ellipsis;
    white-space: nowrap
}