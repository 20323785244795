.gap {
    gap: 13px;
}

.BoxRow {
    display: flex;
    flex-direction: row;
}

.BoxColumn {
    display: flex;
    flex-direction: column;
}

.durationButton {
    font-size: 16px;
    position: relative;
    border-radius: 8px;
    width: 9rem;
    border: 1px solid #B1B1B4;
    display: flex;
    justify-content: center;
    padding: 12px 5px;
}

/* .durationButton::selection {
    border: 1px solid red;
} */

/* manual Booking index file */
.manualBookingForm {
    gap: 24px;
    padding: 20px;
}


/* FOOTER  */

.footerContainer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: 220px !important;
    width: unset !important;
}

/* payment Breakup */

.paymentBreakup {
    gap: 10px;
    width: 30vw;
}