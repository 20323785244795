/* Steps CSS */

.BoxColumn {
  display: flex;
  flex-direction: column;
}

.BoxRow {
  display: flex;
  flex-direction: row;
}

.userDetails {
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  gap: 12px;
}

@media (max-width: 1000px) {
  .userDetails {
    flex-wrap: wrap;
  }
}

.deliveryDetails {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 30px;
}

.buttonGroupDeliveryType {
  width: 150px;
  text-transform: none !important;
  height: 30px;
}

.pickUpTime {
  background-color: white;
  padding: 20px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
}

.paymentBreakup {
  width: 30vw;
}

.dragDrop {
  height: 100px;
  width: 100px;
  border: 1px dashed black;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Plan Type Card CSS  Starts*/

.card {
  position: relative;
  cursor: pointer;
  border-radius: 16px !important;
}

.card.selected {
  background-color: #02696a;
  color: #ffffffcc;
}

/* .card:not(.selected) {
    background-color: #055455;
} */

.planTypeCardBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding: 9px 5px;
  width: 170px;
  height: 85px;

  /* background-color: #e5e5f7; */
}

.CheckoutBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
  /* padding: 32px 32px 32px 40px; */
}

.PlanTypeDetails {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 15px;
  gap: 10px;
  border-radius: 16px;
  border: 2px solid #eeeeee;
  background-color: #01353d14;
}

.PlanTypeDetails.selected {
  border: none;
  background: #053c3d;
  background-blend-mode: color-burn;
}

/* .detailsText {
    color: blue;
} */

/* Plan Type Card CSS  Ends*/

.checkUserForm {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 14px;
  align-items: center;
}

@media (max-width: 767px) {
  .CheckoutBox {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
}

/* Home delivery Address card starts */

.AddressCard {
  position: relative;
  cursor: pointer;
  border-radius: 8px !important;
  width: 220px;
  height: 120px;
}

.AddressCard:hover {
  background: linear-gradient(
      90deg,
      rgba(1, 53, 61, 0.08) 0%,
      rgba(43, 199, 201, 0.08) 60%
    ),
    linear-gradient(0deg, #ffffff, #ffffff);
}

.AddressCard.selected {
  background: #053c3d;
  border: 1px solid;
  border-image-source: linear-gradient(90deg, #2bc7c9 0%, #01353d 45%);
  color: white;
}

.AddressCard:not(.selected) {
  border: 1px solid #b1b1b4;
  color: #636369;
}

.AddressCardUI {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.defaultIcon {
  display: flex;
  align-items: center;
  background: linear-gradient(
      90deg,
      rgba(1, 53, 61, 0.32) 0%,
      rgba(43, 199, 201, 0.32) 60%
    ),
    linear-gradient(0deg, #ffffff, #ffffff);

  padding: 1px 8px 1px 8px;
  border-radius: 60px;
}

/* Home delivery Address card ends */

.CardListContainer {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  white-space: nowrap;
  width: 69vw;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media (max-width: 1136px) {
  .CardListContainer {
    width: 60vw;
  }
}

.CardListContainer > * {
  flex: 0 0 auto;
  margin-right: 10px;
  scroll-snap-align: start;
  /* Optional for snap scrolling */
}

  .scrollBox {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    margin-right: 12px;
  }

/* extra charges in checkout card  CSS starts */

.extraChargesCardMain {
  background-color: #01353d14;
  width: 480px;
  border-radius: 8px;
  overflow: hidden;
}

.extraCardChargesDetails {
  position: absolute;
  display: flex;
  flex-direction: row;
  gap: 28px;
  padding: 12px 16px 12px 16px;
}

/* extra charges in checkout card  CSS ends */

/* Booking Details css */

.BranchDetailsCard {
  width: 300px;
  padding: 12px;
  display: flex;
  border-radius: 8px;
  flex-direction: column;
  border: 1.5px solid #01353d;
}

.timeSlot {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  flex-wrap: wrap;
}

.autoCompleteInput {
  background-color: transparent;
  padding: 4px;
  border: 1px solid #b1b1b4;
  width: 98%;
  border-radius: 8px;
  min-height: 38px;
  height: 5vh;
  max-height: 42px;
  margin: 0px;
  padding-left: 14px;
  box-shadow: 1px 1px 2px 0px #00000021 inset, 11px 10px 32px 0px #0000000a,
    -19px -7px 32px 0px #3333330a;
}

.yesNoIcons {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  border: 1.5px solid;
  border-image-source: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(90deg, #01353d 0%, #2bc7c9 60%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.yesNoIcons:hover {
  background: linear-gradient(
    270.37deg,
    rgba(43, 199, 201, 0.16) -1.53%,
    rgba(27, 196, 125, 0.16) 68.84%,
    rgba(24, 160, 251, 0.16) 101.83%
  );
}

.yesNoIconsSelected {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #01353d;
}

.addressSelection {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 15px;
  justify-content: space-between;
}

.resendOTPButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
