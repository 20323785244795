.BoxRow {
  display: flex;
  flex-direction: row;
}

.BoxColumn {
  display: flex;
  flex-direction: column;
}

/* Plans card */
.card {
  position: relative;
  cursor: pointer;
  border-radius: 8px !important;
}

.card.selected {
  background-image: linear-gradient(
    109.51deg,
    #053c3d 27.34%,
    #0d3d3e 60.5%,
    #124546 93.8%
  );
  border: 1px solid #09cacc;
  color: white;
}

.card:not(.selected) {
  background-image: linear-gradient(
    111.31deg,
    #f0f6f7 0%,
    #f1f1f1 32.1%,
    #f7f8f8 49.56%,
    #fcfcfc 93.74%
  );
}

.planCardBox {
  display: flex;
  justify-content: center;
  gap: 24px;
  padding: 25px 10px;
}

/* helmet badge */

.badge {
  /* border: 1px solid #636369; */
  border: 1px solid #000;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  right: -5px;
  /* z-index: 1; */
  background-color: white;
  padding: 0px 7px !important;
}

.helmetSelectButton {
  min-height: 24px;
  max-height: 35px;
  width: 7vw !important;
  font-size: 12px !important;
  text-transform: none !important;
  color: #000 !important;
  border: 1px solid #000;
}

/* 
.helmetSelectButton::hover {
  border: 1px solid #1bd8da;
}
.badge::hover {
  border: 1px solid #1bd8da;
} */

.IncreasedSelectButton {
  display: flex;
  align-items: center;
  text-align: center;
  padding-left: 17px;
  padding-right: 17px;
  background: #01353d;
}

/* Select Box */

.select {
  border-radius: 8px !important;
  min-height: 38px;
  height: 5vh;
  max-height: 42px;
  margin: 0px;
}

.containerPadding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

/* Heading value Booking detail  */

/* .bookingDetailsValue {
  font-weight: 400;
  font-size: 13px !important;
  font-family: Euclid Circular B !important;
  overflow: hidden;
  width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
} */

.badgeBlink {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  animation: Myblink 0.7s infinite;
}

@keyframes Myblink {
  0%,
  50% {
    width: 6px;
    height: 6px;
    background: #ff7a00;
  }
  100% {
    width: 6px;
    height: 6px;
    background: #eed659;
  }
}

/* PLAN DETAILS TOTAL CHARGE */
.totalChargeBox {
  display: flex;
  padding: 0px 0px 0px 16px;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  background: #01353d3d;
}

.amountBox {
  padding: 8px 16px 8px 16px;
  border-radius: 8px;
}

.fullScreenLoaderStyle {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* ---------------------------- Preview modal Styles -------------------- */
.imagePreviewModal {
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
  color: white;
  border-radius: 0px 0px 2rem 2rem;
  padding: 5px;
}
.downloadButton {
  position: relative;
  bottom: 30px;
  left: 44%;
}
/* ---------------------------- Preview modal styles end -------------------- */

.buttonGroup {
  width: 100px;
  text-transform: none !important;
  height: 30px;
  color: #636369;
}

.autoCompleteInput {
  background-color: transparent;
  padding: 4px;
  border: 1px solid #b1b1b4;
  width: 98%;
  border-radius: 8px;
  min-height: 38px;
  height: 5vh;
  max-height: 42px;
  margin: 0px;
  padding-left: 14px;
  box-shadow: 1px 1px 2px 0px #00000021 inset, 11px 10px 32px 0px #0000000a,
    -19px -7px 32px 0px #3333330a;
}

.resendOTPButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
