/* vehicle status update css starts */
.statusChangeDrawer {
  display: flex;
  flex-direction: column;
  border-left: 4px solid #053c3d;
  color: #ffffff;
  box-shadow: 2px 2px 4px 0px #00000029;
  border-radius: 0px, 8px, 8px, 0px;
  padding: 16px;
  gap: 16px;
}

.vehicleStatusWrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  /* flex-wrap: wrap; */
}

@media (max-width: 1000px) {
  .vehicleStatusWrapper {
    flex-wrap: wrap;
  }
}

/* Approve reject css starts */
.approveRejectButtons {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: white;
}

.containerPadding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.buttonRecevingDetails {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.buttonSendingDetails {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.containerFixed {
  position: fixed;
  bottom: 0;
  padding: 0px;
}

.containerPadding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.vehicleTranfer {
  display: flex;
  width: 100%;
  height: 40px;
  background-color: #fbfbfb;
}

.fileUploadUI {
  padding: 16px;
  border: 2px dashed #b1b1b4;
  border-radius: 17.48px;
  width: 70px;
  cursor: pointer;
}

.fileUploadIN {
  background-color: #00000014;
  border-radius: 17.48px;
  padding: 24px;
  display: flex;
  align-items: flex-start;
  gap: 22px;
}

.bulkUploadWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 35px;
}

.bulkUploadErrorModal {
  border-radius: 16px 16px 0px 0px;
  padding: 0px 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bulkUploadDesc {
  padding: 10px 14px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
