/* Card Vehicle Modal Card Css Starts */

.vehicleModalContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 24px 15px;
  padding: 0px 20px 0px 20px;
}

.vehicleCardMain {
  background-color: #ffffff;
  border-radius: 40px !important;
  box-shadow: 2px 2px 14px 0px #7090b036 !important;
  min-width: 300px;
  width: 320px;
  max-width: 354px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  padding: 16px;
  position: relative;
  height: 350px;
}

.vehicleCardContent {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
