.KYCModal_Wrapper {
  justify-content: space-between;
  padding: 5px 12px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* Selfie modal css starts */
.selfieUpload_Box {
  background: linear-gradient(
    90deg rgba(8 78, 85, 0.08) 0%,
    rgba(43, 199, 201, 0.08) 100%
  );
  border-radius: 10px;
  padding: 10px 20px;
  display: flex;
  gap: 3px;
  align-items: center;
}

.background_Blur {
  filter: blur(2px);
}

.selfie_View_Box {
  background: linear-gradient(
    90deg,
    rgba(8, 78, 85, 0.08) 0%,
    rgba(43, 199, 201, 0.08) 100%
  );
  border-radius: 7px 7px 0px 0px;
  padding: 8px 14px 4px 14px;
}

.selfie_status_Box {
  display: flex;
  justify-content: space-between;
  padding: 4px 14px 8px 14px;
}

.selfie_viewRequest {
  display: flex;
  gap: 3px;
  align-items: flex-start;
}

/* Selfie modal css ends */

/* DL upload Css Starts */

.documentUploadBox {
  border: dotted;
  /* width: 150px;
    height: 133px; */
  border-radius: 4px;
  color: #d0d5dd;
  display: flex;
  padding: 10px 24px 8px 24px;
}

.OtheDocImgUpload_Wrapper {
  display: flex;
  align-items: center;
  gap: 15px;
  border-bottom: 0.85px solid #00000014;
  padding: 15px 0px;
}

.OtherDocView_Wrapper {
  background-color: #00000014;
  border-radius: 17.48px;
  padding: 24px;
  display: flex;
  /* flex-direction: column; */
  align-items: flex-start;
  gap: 22px;
}

/* temporary loader style */
.loaderSpinner {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.toasterStyles {
  width: 500px;
}