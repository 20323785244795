:root {
  --theme: #00353d;
  --hover: rgb(0, 248, 252);
}

.btn-primary {
  margin-top: 30px;
  background-color: var(--theme);
  border-radius: 8px;
  font-weight: bold;
}

.error-message {
  color: rgb(255, 0, 0);
  font-size: small;
}

.root-container {
  height: 100vh;
  width: 100% !important;
}

.bg-theme {
  background-color: var(--theme);
}

.container {
  background-color: #fff;
  position: absolute;
  /* top: 0; */
  /* left: 0; */
  /* height: 100%; */
  width: 83%;
  top: 55px;
  /* padding-left: 10px !important; */
}

.table-model-btn {
  background-color: var(--light-green);
  color: #fff;
}

.table-model-btn:hover {
  background-color: var(--light-green);
  color: #fff;
  box-shadow: 0 3px 6px #2bc6c94f;
}

.custom-container {
  position: absolute;
  height: 100%;
  top: 40px;
}

.pointer {
  cursor: pointer;
}

.MuiBox-root css-1mruxjn {
  height: 100% !important;
}

@media (max-width: 767px) {
  .container {
    width: 100%;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .container {
    width: 100%;
  }
}

.RRT__panel {
  margin-top: 0px;
  padding: 0px;
  border: 0px;
}

.RRT__accordion {
  flex-direction: row;
}

/* ::-webkit-scrollbar {
  width: 0px;
  min-height: 1px;
  background-color: red;
} */

/* Hide scrollbar for Chrome, Safari and Opera */
/* .scrollbar-hidden::-webkit-scrollbar {
  display: none;
} */

/* Hide scrollbar for IE, Edge add Firefox */
/* .scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;
} */

/* * {
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
} */

.table1 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.secondtable {
  width: 100%;
  overflow: auto;
}
.form-check-input:checked {
  background-color: #00353d;
  border-color: #00353d;
}
.btn-admin {
  background-color: #00353d;
  border-color: #00353d;
  color: #fefefe;
}
.btn-admin:hover {
  background-color: #00262c;
  border-color: #00353d;
  color: #fefefe;
}

.disable-btn {
  border: 1px solid #999999 !important;
  background-color: #cccccc !important;
  color: #666666 !important;
  cursor: not-allowed !important;
}

.link-data {
  color: #0000ee !important;
  text-decoration: underline;
  cursor: pointer;
}

.acoordian-wrapper {
  /* background-color: #F2F2F2;
    padding: 20px; */
}
/* .feedback-user-pic {
  width: 68px;
  height: 68px;
  border-radius: 100%;
  background: #ccc;
  text-align: center;
  margin-right: 10px;
} */
/* .feedback-user-pic img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
} */
.user-details .username {
  font-weight: 500;
}

.user-details .booking-id {
  color: #2bc7c9;
}

.cust-likehead {
  font-size: 16px;
  font-family: normal normal normal 13px/16px Euclid Circular B;
}
.tags-wrap {
  list-style-type: none;
  padding: 0;
  display: flex;
  white-space: nowrap;
  flex-wrap: wrap;
  text-align: start;
}

.tags-wrap li {
  background: transparent linear-gradient(90deg, #2ec6c8 0%, #05f7cf 100%) 0% 0%
    no-repeat padding-box;
  margin: 10px;
  padding: 4px 15px;
  border-radius: 24px;
  color: White;
  font-size: 13px;
  margin-left: 0px;
  font-family: normal normal normal 13px/16px Euclid Circular B;
}

.review-box{
  display: flex;
  flex-wrap: wrap;
  gap:25px
}
.cust-review h4,
.admin-review h4 {
  font-size: 16px;
  font-family: normal normal normal 13px/16px Euclid Circular B;
  color: #0d2122;
}

.cust-review p,
.admin-review p {
  font-size: 16px;
  font-family: normal normal normal 13px/16px Euclid Circular B;
  white-space: wrap;
  color: #0d2122;
  font-weight: 400;
}
.logo-text {
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
  text-align: center;
  color: red;
  font-size: 2rem;
  margin-top: 0px;
}
